var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
let WineListFiltersForm = class WineListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            // {
            //   id: "lotNumber",
            //   label: t("management.wines.fields.lotNumber"),
            //   name: ["lotNumber"],
            //   render: (props) => (
            //     <Ant.Form.Item {...props}>
            //       <Ant.Input />
            //     </Ant.Form.Item>
            //   ),
            // },
            // {
            //   id: "sustainabilities",
            //   label: t("management.wines.fields.sustainabilities"),
            //   name: ["sustainabilities"],
            //   render: (props) => (
            //     <Ant.Form.Item {...props}>
            //       <Ant.Select
            //         mode="multiple"
            //         placeholder={t("management.wines.fields.sustainabilities")}
            //       >
            //         <Ant.Select.Option value="GREEN_DOT" key="GREEN_DOT">
            //           Green Dot
            //         </Ant.Select.Option>
            //         <Ant.Select.Option value="TRIMAN" key="TRIMAN">
            //           Triman
            //         </Ant.Select.Option>
            //         <Ant.Select.Option value="RECYCLE" key="RECYCLE">
            //           Recycle
            //         </Ant.Select.Option>
            //         <Ant.Select.Option value="EURO_LEAF" key="EURO_LEAF">
            //           Euro Leaf
            //         </Ant.Select.Option>
            //         <Ant.Select.Option value="WFCP" key="WFCP">
            //           Wfcp
            //         </Ant.Select.Option>
            //         <Ant.Select.Option value="WFCP_PLUS" key="WFCP_PLUS">
            //           Wfcp Plus
            //         </Ant.Select.Option>
            //       </Ant.Select>
            //     </Ant.Form.Item>
            //   ),
            // },
            // {
            //   id: "responsibleConsumptions",
            //   label: t("management.wines.fields.responsibleConsumptions"),
            //   name: ["responsibleConsumptions"],
            //   render: (props) => (
            //     <Ant.Form.Item {...props}>
            //       <Ant.Select
            //         mode="multiple"
            //         placeholder={t("management.wines.fields.responsibleConsumptions")}
            //       >
            //         <Ant.Select.Option value="NO_PREGNANCY" key="NO_PREGNANCY">
            //           No Pregnancy
            //         </Ant.Select.Option>
            //         <Ant.Select.Option value="NO_DRIVING" key="NO_DRIVING">
            //           No Driving
            //         </Ant.Select.Option>
            //         <Ant.Select.Option value="EIGHTEEN_PLUS" key="EIGHTEEN_PLUS">
            //           Eighteen Plus
            //         </Ant.Select.Option>
            //         <Ant.Select.Option
            //           value="WINE_IN_MODERATION"
            //           key="WINE_IN_MODERATION"
            //         >
            //           Wine In Moderation
            //         </Ant.Select.Option>
            //       </Ant.Select>
            //     </Ant.Form.Item>
            //   ),
            // },
            {
                id: "generalDetails",
                label: t("management.wines.fields.generalDetails"),
                name: ["generalDetails"],
                columns: true,
                nest: [
                    {
                        id: "name",
                        label: t("management.wines.fields.generalDetails.name"),
                        name: ["generalDetails", "name"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "brand",
                        label: t("management.wines.fields.generalDetails.brand"),
                        name: ["generalDetails", "brand"],
                        component: Ant.Input,
                    },
                    // {
                    //   id: "vintageYear",
                    //   label: t("management.wines.fields.generalDetails.vintageYear"),
                    //   name: ["generalDetails", "vintageYear"],
                    //   component: Ant.InputNumber,
                    // },
                    // {
                    //   id: "country",
                    //   label: t("management.wines.fields.generalDetails.country"),
                    //   name: ["generalDetails", "country"],
                    //   component: Ant.Input,
                    // },
                    // {
                    //   id: "alcoholByVolumePercentage",
                    //   label: t(
                    //     "management.wines.fields.generalDetails.alcoholByVolumePercentage",
                    //   ),
                    //   name: ["generalDetails", "alcoholByVolumePercentage"],
                    //   component: Ant.InputNumber,
                    // },
                    // {
                    //   id: "bottleSizeInMl",
                    //   label: t("management.wines.fields.generalDetails.bottleSizeInMl"),
                    //   name: ["generalDetails", "bottleSizeInMl"],
                    //   component: Ant.InputNumber,
                    // },
                    // {
                    //   id: "category",
                    //   label: t("management.wines.fields.generalDetails.category"),
                    //   name: ["generalDetails", "category"],
                    //   render: (props) => {
                    //     const options = [
                    //       { value: "RED_WINE", label: "Red Wine" },
                    //       { value: "WHITE_WINE", label: "White Wine" },
                    //       { value: "ROSE_WINE", label: "Rose Wine" },
                    //       { value: "SPARKLING_WINE", label: "Sparkling Wine" },
                    //       { value: "DESSERT_WINE", label: "Dessert Wine" },
                    //       { value: "FORTIFIED_WINE", label: "Fortified Wine" },
                    //       { value: "OTHER", label: "Other" },
                    //     ];
                    //     return (
                    //       <Ant.Form.Item {...props}>
                    //         <Ant.Select
                    //           allowClear
                    //           placeholder={t(
                    //             "management.wines.fields.generalDetails.category",
                    //           )}
                    //         >
                    //           {options.map((option) => (
                    //             <Ant.Select.Option
                    //               value={option.value}
                    //               key={option.value}
                    //             >
                    //               {t(
                    //                 `management.wines.fields.generalDetails.category_enums.${option.label.toLowerCase()}`,
                    //               )
                    //                 ? t(
                    //                     `management.wines.fields.generalDetails.category_enums.${option.label.toLowerCase()}`,
                    //                   )
                    //                 : option.label}
                    //             </Ant.Select.Option>
                    //           ))}
                    //         </Ant.Select>
                    //       </Ant.Form.Item>
                    //     );
                    //   },
                    // },
                    // {
                    //   id: "ingredients",
                    //   label: t("management.wines.fields.generalDetails.ingredients"),
                    //   name: ["generalDetails", "ingredients"],
                    //   required: true,
                    //   isList: true,
                    //   nest: [],
                    // },
                    // {
                    //   id: "allergens",
                    //   label: t("management.wines.fields.generalDetails.allergens"),
                    //   name: ["generalDetails", "allergens"],
                    //   isList: true,
                    //   nest: [],
                    // },
                    // {
                    //   id: "varieties",
                    //   label: t("management.wines.fields.generalDetails.varieties"),
                    //   name: ["generalDetails", "varieties"],
                    //   isList: true,
                    //   nest: [],
                    // },
                    // {
                    //   id: "description",
                    //   label: t("management.wines.fields.generalDetails.description"),
                    //   name: ["generalDetails", "description"],
                    //   isList: true,
                    //   nest: [],
                    // },
                    // {
                    //   id: "expirationDate",
                    //   label: t("management.wines.fields.generalDetails.expirationDate"),
                    //   name: ["generalDetails", "expirationDate"],
                    //   render: (props) => (
                    //     <Ant.Form.Item {...props}>
                    //       <UIComponents.DatePicker required={false} />
                    //     </Ant.Form.Item>
                    //   ),
                    // },
                ],
            },
            // {
            //   id: "nutritionalDetails",
            //   label: t("management.wines.fields.nutritionalDetails"),
            //   name: ["nutritionalDetails"],
            //   columns: true,
            //   nest: [
            //     {
            //       id: "energyInKcal",
            //       label: t("management.wines.fields.nutritionalDetails.energyInKcal"),
            //       name: ["nutritionalDetails", "energyInKcal"],
            //       component: Ant.InputNumber,
            //     },
            //     {
            //       id: "fatInGrams",
            //       label: t("management.wines.fields.nutritionalDetails.fatInGrams"),
            //       name: ["nutritionalDetails", "fatInGrams"],
            //       component: Ant.InputNumber,
            //     },
            //     {
            //       id: "carbohydratesInGrams",
            //       label: t(
            //         "management.wines.fields.nutritionalDetails.carbohydratesInGrams",
            //       ),
            //       name: ["nutritionalDetails", "carbohydratesInGrams"],
            //       component: Ant.InputNumber,
            //     },
            //     {
            //       id: "proteinInGrams",
            //       label: t(
            //         "management.wines.fields.nutritionalDetails.proteinInGrams",
            //       ),
            //       name: ["nutritionalDetails", "proteinInGrams"],
            //       component: Ant.InputNumber,
            //     },
            //     {
            //       id: "saltInMilliGrams",
            //       label: t("management.wines.fields.nutritionalDetails.saltInMilliGrams"),
            //       name: ["nutritionalDetails", "saltInMilliGrams"],
            //       component: Ant.InputNumber,
            //     },
            //   ],
            // },
            // {
            //   id: "company",
            //   label: t("management.wines.fields.company"),
            //   name: ["company"],
            //   columns: true,
            //   nest: [
            //     {
            //       id: "name",
            //       label: t("management.wines.fields.company.name"),
            //       name: ["company", "name"],
            //       component: Ant.Input,
            //     },
            //     {
            //       id: "websiteUrl",
            //       label: t("management.wines.fields.company.websiteUrl"),
            //       name: ["company", "websiteUrl"],
            //       component: Ant.Input,
            //     },
            //   ],
            // },
            // {
            //   id: "wineryMemberId",
            //   label: t("management.wines.fields.wineryMember"),
            //   name: ["wineryMemberId"],
            //   render: (props) => (
            //     <Ant.Form.Item {...props}>
            //       <UIComponents.RemoteSelect
            //         collectionClass={WineryMembersCollection}
            //         field="_id"
            //         placeholder="Please select an option"
            //         mode="multiple"
            //       />
            //     </Ant.Form.Item>
            //   ),
            // },
        ]);
    }
};
WineListFiltersForm = __decorate([
    Service({ transient: true })
], WineListFiltersForm);
export { WineListFiltersForm };
