import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useQuery } from "@apollo/client";
import { useUIComponents, useTranslate, useRouter } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import { ADMIN_WINERY_MEMBER_CREATE, ADMIN_WINERY_MEMBER_GROUP_GET_ALL, } from "@bundles/UIAppBundle/queries";
import * as Ant from "antd";
import { message } from "antd";
const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export function WineryMembersCreate() {
    var _a;
    const [form] = Ant.Form.useForm();
    const router = useRouter();
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const [messageApi, contextHolder] = message.useMessage();
    const { data: fetchWineryData, error: fetchWineryError } = useQuery(ADMIN_WINERY_MEMBER_GROUP_GET_ALL);
    const [createWineryMember, { loading: createWineryMemberLoading, error }] = useMutation(ADMIN_WINERY_MEMBER_CREATE);
    const handleCreateWineryMember = (document) => {
        createWineryMember({ variables: { input: document } })
            .then(() => {
            messageApi.open({
                type: "success",
                content: "WineryMember created successfully",
            });
            setTimeout(() => {
                router.go(Routes.WINERY_MEMBERS_LIST);
                location.reload();
            }, 1000);
        })
            .catch((e) => {
            messageApi.open({
                type: "error",
                content: e.message,
            });
        });
    };
    const INPUT_RULES = [{ required: true, message: "This field is required!" }];
    return (_jsxs(UIComponents.AdminLayout, { children: [contextHolder, fetchWineryError && (_jsx(Ant.Alert, { type: "error", message: fetchWineryError }, void 0)), _jsx(Ant.PageHeader, { title: t("management.winery_members.create.header"), onBack: () => window.history.back() }, void 0), _jsx(Ant.Card, { children: _jsxs(Ant.Form, Object.assign({}, formLayout, { form: form, requiredMark: "optional", onFinish: handleCreateWineryMember }, { children: [_jsx(Ant.Form.Item, Object.assign({ label: "First Name", name: "firstName", rules: INPUT_RULES }, { children: _jsx(Ant.Input, { placeholder: "First Name" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Last Name", name: "lastName", rules: INPUT_RULES }, { children: _jsx(Ant.Input, { placeholder: "Last Name" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Winery", name: "wineryName", rules: INPUT_RULES }, { children: _jsx(Ant.AutoComplete, Object.assign({ options: ((_a = fetchWineryData === null || fetchWineryData === void 0 ? void 0 : fetchWineryData.AdminWineryGetAll) !== null && _a !== void 0 ? _a : []).map((winery) => ({
                                    label: winery.name,
                                    value: winery.name,
                                })), allowClear: true, showArrow: true }, { children: _jsx(Ant.Input, { placeholder: "input here" }, void 0) }), void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Email", name: "username", rules: INPUT_RULES }, { children: _jsx(Ant.Input, { placeholder: "Email" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({}, formTailLayout, { children: _jsx(Ant.Button, Object.assign({ type: "primary", htmlType: "submit", loading: createWineryMemberLoading }, { children: t("generics.submit") }), void 0) }), void 0)] }), void 0) }, void 0)] }, void 0));
}
