import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const INPUT_RULES = [{ required: true, message: "This field is required!" }];
const CalculateCarbohydrateModal = ({ status, onSubmit, onClose }) => {
    return (_jsx(Ant.Modal, Object.assign({ title: "Carbohydrates Calculator", open: status, onCancel: onClose, footer: null }, { children: _jsxs(Ant.Form, Object.assign({}, formLayout, { onFinish: onSubmit }, { children: [_jsx(Ant.Form.Item, Object.assign({ labelAlign: "left", label: "Carbohydrate (g/liter)", name: "carbohydrate", rules: INPUT_RULES }, { children: _jsx(Ant.Input, { type: "number", min: 0, placeholder: "Carbohydrate (g/liter)" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ labelAlign: "left", label: "Residual Sugar (g/liter)", name: "sugar", rules: INPUT_RULES }, { children: _jsx(Ant.Input, { type: "number", min: 0, placeholder: "Residual Sugar (g/liter)" }, void 0) }), void 0), _jsx(Ant.Form.Item, { children: _jsx(Ant.Button, Object.assign({ type: "primary", htmlType: "submit" }, { children: "Calculate" }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default CalculateCarbohydrateModal;
