import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGuardian, useRouter, useTranslate } from "@bluelibs/x-ui";
import { useState } from "react";
import { LockOutlined } from "@ant-design/icons";
import { Routes } from "@bundles/UIAppBundle";
import { Form, Input, Button, Row, Col, Alert, Card } from "antd";
export function ResetPassword(props) {
    const guardian = useGuardian();
    const router = useRouter();
    const tl = useTranslate("authentication.resetPassword");
    const [submitError, setSubmitError] = useState(null);
    const [isCompleted, setIsComplete] = useState(false);
    const onSubmit = (data) => {
        const { email, password } = data;
        guardian
            .resetPassword(email, props.token, password)
            .then(() => {
            setIsComplete(true);
            setTimeout(() => {
                router.go(Routes.HOME);
            }, 4500);
        })
            .catch((err) => {
            setSubmitError(err.toString());
        });
    };
    const style = { minHeight: "100vh" };
    return (_jsx(Row, Object.assign({ justify: "center", align: "middle", style: style, className: "reset-password-page" }, { children: _jsx(Col, Object.assign({ sm: 24, md: 12, lg: 6 }, { children: _jsxs(Card, Object.assign({ title: tl("header") }, { children: [isCompleted && _jsx(Alert, { type: "success", message: tl("success_msg") }, void 0), !isCompleted && (_jsxs(Form, Object.assign({ onFinish: (data) => onSubmit(data), className: "authentication-form" }, { children: [_jsx(Form.Item, Object.assign({ name: "email", rules: [{ required: true }] }, { children: _jsx(Input, { placeholder: tl("fields.email") }, void 0) }), void 0), _jsx(Form.Item, Object.assign({ name: "password", rules: [{ required: true }] }, { children: _jsx(Input, { prefix: _jsx(LockOutlined, { className: "site-form-item-icon" }, void 0), type: "password", placeholder: tl("fields.password") }, void 0) }), void 0), _jsx(Form.Item, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", className: "authentication-form-button" }, { children: tl("submit") }), void 0) }, void 0), submitError && _jsx(Alert, { message: submitError, type: "error" }, void 0)] }), void 0))] }), void 0) }), void 0) }), void 0));
}
