import { WineriesList } from "../components/List/WineriesList";
import { WineriesCreate } from "../components/Create/WineriesCreate";
import { WineriesEdit } from "../components/Edit/WineriesEdit";
import { WineriesView } from "../components/View/WineriesView";
import { HomeOutlined } from "@ant-design/icons";
import { UserRole } from "@root/api.types";
export const WINERIES_LIST = {
    path: "/admin/wineries",
    roles: [UserRole.ADMIN],
    component: WineriesList,
    menu: {
        key: "WINERIES_LIST",
        label: "management.wineries.menu.title",
        icon: HomeOutlined,
    },
};
export const WINERIES_CREATE = {
    path: "/admin/wineries/create",
    roles: [UserRole.ADMIN],
    component: WineriesCreate,
};
export const WINERIES_EDIT = {
    path: "/admin/wineries/:id/edit",
    roles: [UserRole.ADMIN],
    component: WineriesEdit,
};
export const WINERIES_VIEW = {
    path: "/admin/wineries/:id/view",
    roles: [UserRole.ADMIN],
    component: WineriesView,
};
