import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { useUIComponents, useRouter, useTranslate } from "@bluelibs/x-ui";
import { useMutation, useQuery } from "@apollo/client";
import { ADMIN_WINERY_MEMBER_GROUP_GET_ALL, ADMIN_WINERY_MEMBER_UPDATE, WINERY_MEMBERS_FIND_ONE, } from "@bundles/UIAppBundle/queries";
import { useEffect } from "react";
const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export function WineryMembersEdit(props) {
    var _a, _b, _c;
    const [form] = Ant.Form.useForm();
    const router = useRouter();
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const [messageApi, contextHolder] = Ant.message.useMessage();
    const { data: fetchWineryData } = useQuery(ADMIN_WINERY_MEMBER_GROUP_GET_ALL);
    const { data: fetchWineryMemberData, error: fetchWineryMemberError } = useQuery(WINERY_MEMBERS_FIND_ONE, {
        variables: {
            query: {
                filters: {
                    _id: {
                        $objectId: props.id,
                    },
                },
            },
        },
    });
    const [updateWineryMember, { loading: updateWineryMemberLoading }] = useMutation(ADMIN_WINERY_MEMBER_UPDATE);
    const handleUpdateWineryMember = (document) => {
        const payload = {
            wineryMemberId: props.id,
            ...document,
            enable: (document === null || document === void 0 ? void 0 : document.enable) ? true : false,
        };
        updateWineryMember({ variables: { input: payload } })
            .then(() => {
            messageApi.open({
                type: "success",
                content: "Winery member updated successfully",
            });
            setTimeout(() => {
                router.go(Routes.WINERY_MEMBERS_LIST);
                location.reload();
            }, 1000);
        })
            .catch((e) => {
            messageApi.open({
                type: "error",
                content: e.message,
            });
        });
    };
    const INPUT_RULES = [{ required: true, message: "This field is required!" }];
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        if (fetchWineryMemberData === null || fetchWineryMemberData === void 0 ? void 0 : fetchWineryMemberData.WineryMembersFindOne) {
            form.setFieldValue("firstName", (_c = (_b = (_a = fetchWineryMemberData === null || fetchWineryMemberData === void 0 ? void 0 : fetchWineryMemberData.WineryMembersFindOne) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.firstName);
            form.setFieldValue("lastName", (_f = (_e = (_d = fetchWineryMemberData === null || fetchWineryMemberData === void 0 ? void 0 : fetchWineryMemberData.WineryMembersFindOne) === null || _d === void 0 ? void 0 : _d.user) === null || _e === void 0 ? void 0 : _e.profile) === null || _f === void 0 ? void 0 : _f.lastName);
            form.setFieldValue("wineryName", (_h = (_g = fetchWineryMemberData === null || fetchWineryMemberData === void 0 ? void 0 : fetchWineryMemberData.WineryMembersFindOne) === null || _g === void 0 ? void 0 : _g.winery) === null || _h === void 0 ? void 0 : _h.name);
            form.setFieldValue("username", (_k = (_j = fetchWineryMemberData === null || fetchWineryMemberData === void 0 ? void 0 : fetchWineryMemberData.WineryMembersFindOne) === null || _j === void 0 ? void 0 : _j.user) === null || _k === void 0 ? void 0 : _k.email);
            form.setFieldValue("enable", (_m = (_l = fetchWineryMemberData === null || fetchWineryMemberData === void 0 ? void 0 : fetchWineryMemberData.WineryMembersFindOne) === null || _l === void 0 ? void 0 : _l.user) === null || _m === void 0 ? void 0 : _m.isEnabled);
        }
    }, [fetchWineryMemberData]);
    return (_jsxs(UIComponents.AdminLayout, { children: [contextHolder, fetchWineryMemberError && (_jsx(Ant.Alert, { type: "error", message: fetchWineryMemberError }, void 0)), _jsx(Ant.PageHeader, { title: t("management.winery_members.edit.header"), onBack: () => window.history.back() }, void 0), _jsx(Ant.Card, { children: _jsxs(Ant.Form, Object.assign({}, formLayout, { form: form, onFinish: handleUpdateWineryMember }, { children: [_jsx(Ant.Form.Item, Object.assign({ label: "First Name", name: "firstName", rules: INPUT_RULES }, { children: _jsx(Ant.Input, { placeholder: "First Name" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Last Name", name: "lastName", rules: INPUT_RULES }, { children: _jsx(Ant.Input, { placeholder: "Last Name" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Winery", name: "wineryName", rules: INPUT_RULES }, { children: _jsx(Ant.AutoComplete, Object.assign({ options: ((_a = fetchWineryData === null || fetchWineryData === void 0 ? void 0 : fetchWineryData.AdminWineryGetAll) !== null && _a !== void 0 ? _a : []).map((winery) => ({
                                    label: winery.name,
                                    value: winery.name,
                                })), allowClear: true, showArrow: true }, { children: _jsx(Ant.Input, { placeholder: "input here" }, void 0) }), void 0) }), void 0), fetchWineryMemberData && (_jsx(Ant.Form.Item, Object.assign({ label: "Enable", name: "enable" }, { children: _jsx(Ant.Checkbox, { onChange: (e) => form.setFieldValue("enable", e.target.checked), defaultChecked: (_c = (_b = fetchWineryMemberData === null || fetchWineryMemberData === void 0 ? void 0 : fetchWineryMemberData.WineryMembersFindOne) === null || _b === void 0 ? void 0 : _b.user) === null || _c === void 0 ? void 0 : _c.isEnabled }, void 0) }), void 0)), _jsx(Ant.Form.Item, Object.assign({}, formTailLayout, { children: _jsx(Ant.Button, Object.assign({ type: "primary", htmlType: "submit", loading: updateWineryMemberLoading }, { children: t("generics.submit") }), void 0) }), void 0)] }), void 0) }, void 0)] }, void 0));
}
