import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents, use, useTranslate, useRouter } from "@bluelibs/x-ui";
import { WineryCreateForm } from "../../config/WineryCreateForm";
import * as Ant from "antd";
import { message } from "antd";
import { useMutation } from "@apollo/client";
import { ADMIN_WINERY_MEMBER_GROUP_CREATE, } from "@bundles/UIAppBundle/queries";
import { Routes } from "@bundles/UIAppBundle";
const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export function WineriesCreate() {
    const UIComponents = useUIComponents();
    const router = useRouter();
    const t = useTranslate();
    const form = use(WineryCreateForm, { transient: true });
    form.build();
    const [messageApi, contextHolder] = message.useMessage();
    const [createWinery, { loading, error }] = useMutation(ADMIN_WINERY_MEMBER_GROUP_CREATE);
    const handleCreateWinery = (document) => {
        createWinery({ variables: { input: document } })
            .then(() => {
            messageApi.open({
                type: "success",
                content: "Winery created successfully",
            });
            setTimeout(() => {
                router.go(Routes.WINERIES_LIST);
                location.reload();
            }, 1000);
        })
            .catch((e) => {
            messageApi.open({
                type: "error",
                content: e.message,
            });
        });
    };
    return (_jsxs(UIComponents.AdminLayout, { children: [contextHolder, _jsx(Ant.PageHeader, { title: t("management.wineries.create.header"), onBack: () => window.history.back() }, void 0), _jsx(Ant.Card, { children: _jsxs(Ant.Form, Object.assign({}, formLayout, { requiredMark: "optional", onFinish: handleCreateWinery }, { children: [form.render(), _jsx(Ant.Form.Item, Object.assign({}, formTailLayout, { children: _jsx(Ant.Button, Object.assign({ loading: loading, type: "primary", htmlType: "submit" }, { children: t("generics.submit") }), void 0) }), void 0)] }), void 0) }, void 0)] }, void 0));
}
