var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { UsersCollection } from "@bundles/UIAppBundle/collections";
let UserListFiltersForm = class UserListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "roles",
                label: t("management.users.fields.roles"),
                name: ["roles"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.users.fields.roles") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "ADMIN" }, { children: "Admin" }), "ADMIN"), _jsx(Ant.Select.Option, Object.assign({ value: "WINERY_MEMBER" }, { children: "Winery Member" }), "WINERY_MEMBER")] }), void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.users.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.users.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.users.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.users.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "isEnabled",
                label: t("management.users.fields.isEnabled"),
                name: ["isEnabled"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "profile",
                label: t("management.users.fields.profile"),
                name: ["profile"],
                columns: true,
                nest: [
                    {
                        id: "firstName",
                        label: t("management.users.fields.profile.firstName"),
                        name: ["profile", "firstName"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "lastName",
                        label: t("management.users.fields.profile.lastName"),
                        name: ["profile", "lastName"],
                        required: true,
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "createdById",
                label: t("management.users.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.users.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "updatedById",
                label: t("management.users.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.users.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
UserListFiltersForm = __decorate([
    Service({ transient: true })
], UserListFiltersForm);
export { UserListFiltersForm };
