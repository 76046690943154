import { WineryMembersList } from "../components/List/WineryMembersList";
import { WineryMembersCreate } from "../components/Create/WineryMembersCreate";
import { WineryMembersEdit } from "../components/Edit/WineryMembersEdit";
import { WineryMembersView } from "../components/View/WineryMembersView";
import { TeamOutlined } from "@ant-design/icons";
import { UserRole } from "@root/api.types";
export const WINERY_MEMBERS_LIST = {
    path: "/admin/winery-members",
    roles: [UserRole.ADMIN],
    component: WineryMembersList,
    menu: {
        key: "WINERY_MEMBERS_LIST",
        label: "management.winery_members.menu.title",
        icon: TeamOutlined,
    },
};
export const WINERY_MEMBERS_CREATE = {
    path: "/admin/winery-members/create",
    roles: [UserRole.ADMIN],
    component: WineryMembersCreate,
};
export const WINERY_MEMBERS_EDIT = {
    path: "/admin/winery-members/:id/edit",
    roles: [UserRole.ADMIN],
    component: WineryMembersEdit,
};
export const WINERY_MEMBERS_VIEW = {
    path: "/admin/winery-members/:id/view",
    roles: [UserRole.ADMIN],
    component: WineryMembersView,
};
