import { gql } from "@apollo/client";
export const WINE_GET_BY_QR = gql `
  query WineGetByQr($input: WineGetByQrInput!) {
    WineGetByQr(input: $input) {
      _id
      company {
        name
        websiteUrl
      }
      companyLogo {
        downloadUrl
      }
      companyLogoId
      bottlePhoto {
        downloadUrl
      }
      bottlePhotoId
      responsibleConsumptionPhotos {
        downloadUrl
      }
      responsibleConsumptionPhotosIds
      generalDetails {
        name
        brand
        vintageYear
        description {
          text
          values {
            lang
            text
          }
        }
        country
        alcoholByVolumePercentage
        bottleSizeInMl
        category
        varieties {
          text
          values {
            lang
            text
          }
        }
        ingredients {
          text
          values {
            lang
            text
          }
        }
        allergens {
          text
          values {
            lang
            text
          }
        }
        expirationDate
      }
      lotNumber
      nutritionalDetails {
        energyInKcal
        fatInGrams
        carbohydratesInGrams
        ofWhichSugarInGrams
        proteinInGrams
        saltInMilliGrams
      }
      wineryMemberId
      qrCode
      responsibleConsumptions
      sustainabilities
    }
  }
`;
