import { gql } from "@apollo/client";
export const WINES_GET_ALL = gql `
  query WineGetAll($input: WineGetAllInput!) {
    WineGetAll(input: $input) {
      pageCount
      skip
      limit
      data {
        _id
        company {
          name
          websiteUrl
        }
        companyLogo {
          _id
          downloadUrl
        }
        companyLogoId
        generalDetails {
          name
          brand
          vintageYear
          country
          alcoholByVolumePercentage
          bottleSizeInMl
          category
          description {
            text
            values {
              lang
              text
            }
          }
          varieties {
            text
            values {
              lang
              text
            }
          }
          ingredients {
            text
            values {
              lang
              text
            }
          }
          allergens {
            text
          }
          expirationDate
        }
        lotNumber
        nutritionalDetails {
          energyInKcal
          fatInGrams
          carbohydratesInGrams
          ofWhichSugarInGrams
          proteinInGrams
          saltInMilliGrams
        }
        wineryMemberId
        qrCode
        responsibleConsumptions
        sustainabilities
      }
    }
  }
`;
