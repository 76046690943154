import "./i18n";
import { WINERY_MEMBERS_LIST as BASE_WINERY_MEMBERS_LIST, WINERY_MEMBERS_CREATE as BASE_WINERY_MEMBERS_CREATE, WINERY_MEMBERS_EDIT as BASE_WINERY_MEMBERS_EDIT, WINERY_MEMBERS_VIEW as BASE_WINERY_MEMBERS_VIEW, } from "./config/routes";
export const WINERY_MEMBERS_LIST = {
    ...BASE_WINERY_MEMBERS_LIST,
};
export const WINERY_MEMBERS_CREATE = {
    ...BASE_WINERY_MEMBERS_CREATE,
};
export const WINERY_MEMBERS_EDIT = {
    ...BASE_WINERY_MEMBERS_EDIT,
};
export const WINERY_MEMBERS_VIEW = {
    ...BASE_WINERY_MEMBERS_VIEW,
};
