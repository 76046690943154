import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRouter, useUIComponents, useTranslate, use, I18NService, } from "@bluelibs/x-ui";
import { useState, useMemo } from "react";
import { PlusOutlined, FilterOutlined, DownOutlined, ScanOutlined, } from "@ant-design/icons";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { features } from "../../config/features";
import { WinesListFilters } from "./WinesListFilters";
import { WINES_GET_ALL, WINE_DELETE, WINE_DUPLICATE, } from "@bundles/UIAppBundle/queries";
import { useMutation, useQuery } from "@apollo/client";
import { AcceptedLanguage, UserRole, } from "@root/api.types";
import dayjs from "dayjs";
import { QR } from "@bundles/UIAppBundle/components";
import { useAppGuardian } from "@bundles/UIAppBundle/services/AppGuardian";
const LIMIT = 10;
export function WinesList() {
    var _a, _b;
    const UIComponents = useUIComponents();
    const router = useRouter();
    const t = useTranslate();
    const i18n = use(I18NService);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const guardian = useAppGuardian();
    const [page, setPage] = useState(1);
    const [messageApi, contextHolder] = Ant.message.useMessage();
    i18n.setLocale(AcceptedLanguage.en);
    const [qrModal, setQrModal] = useState(false);
    const [selectedWines, setSelectedWines] = useState([]);
    const [filters, setFilters] = useState({ name: undefined, brand: undefined });
    const { refetch, data, error, loading } = useQuery(WINES_GET_ALL, {
        variables: {
            input: {
                options: { limit: LIMIT, skip: (page - 1) * LIMIT },
                filters,
            },
        },
    });
    const [deleteWine] = useMutation(WINE_DELETE);
    const [duplicateWine] = useMutation(WINE_DUPLICATE);
    const handleDeleteWine = (id) => {
        deleteWine({ variables: { input: { wineId: id } } })
            .then(() => {
            messageApi.open({
                type: "success",
                content: "Wine deleted successfully",
            });
            refetch();
        })
            .catch((e) => {
            messageApi.open({
                type: "error",
                content: e.message,
            });
        });
    };
    const handleDuplicateWine = (id) => {
        duplicateWine({ variables: { input: { wineId: id } } })
            .then(() => {
            messageApi.open({
                type: "success",
                content: "Wine duplicated successfully",
            });
            refetch();
        })
            .catch((e) => {
            messageApi.open({
                type: "error",
                content: e.message,
            });
        });
    };
    const items = (wine) => [
        {
            key: "view",
            label: (_jsx("a", Object.assign({ href: `${process.env.QR_BASE_URL}/qr/${wine.qrCode}`, target: "_blank", rel: "noreferrer" }, { children: "View" }), void 0)),
        },
        {
            key: "printQR",
            label: "Print QR",
            onClick: () => {
                var _a;
                setSelectedWines([
                    {
                        _id: wine._id,
                        name: (_a = wine === null || wine === void 0 ? void 0 : wine.generalDetails) === null || _a === void 0 ? void 0 : _a.name,
                        qr: wine.qrCode,
                    },
                ]);
                setQrModal(true);
            },
        },
        {
            key: "edit",
            label: "Edit",
            onClick: () => {
                router.go({
                    path: `${router.history.location.pathname}/${wine._id}/edit`,
                });
            },
        },
        {
            key: "duplicate",
            label: "Duplicate",
            onClick: () => {
                handleDuplicateWine(wine._id);
            },
        },
        guardian.state.user.roles.includes(UserRole.ADMIN) && {
            key: "delete",
            label: (_jsx(Ant.Popconfirm, Object.assign({ title: "Sure to delete?", onConfirm: () => handleDeleteWine(wine._id) }, { children: _jsx("a", { children: "Delete" }, void 0) }), void 0)),
        },
    ];
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedWines(selectedRows.map(({ _id, generalDetails, qrCode }) => ({
                _id,
                name: generalDetails === null || generalDetails === void 0 ? void 0 : generalDetails.name,
                qr: qrCode,
            })));
        },
        getCheckboxProps: (record) => ({
            disabled: false,
            name: record.name,
        }),
    };
    const tableDataSource = useMemo(() => {
        var _a, _b;
        const dataSource = ((_b = (_a = data === null || data === void 0 ? void 0 : data.WineGetAll) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : []).map((item) => ({
            key: item._id,
            ...item,
        }));
        const columns = [
            {
                title: "Name",
                key: "name",
                render: (wine) => { var _a, _b; return `${(_b = (_a = wine === null || wine === void 0 ? void 0 : wine.generalDetails) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "-"}`; },
            },
            {
                title: "Brand",
                key: "brand",
                render: (wine) => { var _a, _b; return `${(_b = (_a = wine === null || wine === void 0 ? void 0 : wine.generalDetails) === null || _a === void 0 ? void 0 : _a.brand) !== null && _b !== void 0 ? _b : "-"}`; },
            },
            {
                title: "Country",
                key: "country",
                render: (wine) => { var _a, _b; return `${(_b = (_a = wine === null || wine === void 0 ? void 0 : wine.generalDetails) === null || _a === void 0 ? void 0 : _a.country) !== null && _b !== void 0 ? _b : "-"}`; },
            },
            {
                title: "Vintage Year",
                key: "vintageYear",
                render: (wine) => { var _a, _b; return `${(_b = (_a = wine === null || wine === void 0 ? void 0 : wine.generalDetails) === null || _a === void 0 ? void 0 : _a.vintageYear) !== null && _b !== void 0 ? _b : "-"}`; },
            },
            {
                title: "Alcohol By Volume Percentage",
                key: "alcoholByVolumePercentage",
                render: (wine) => {
                    var _a;
                    return `${((_a = wine === null || wine === void 0 ? void 0 : wine.generalDetails) === null || _a === void 0 ? void 0 : _a.alcoholByVolumePercentage)
                        ? wine.generalDetails.alcoholByVolumePercentage + "%"
                        : "-"}`;
                },
            },
            {
                title: "Expiration Date",
                key: "expirationDate",
                render: (wine) => {
                    var _a;
                    return `${((_a = wine === null || wine === void 0 ? void 0 : wine.generalDetails) === null || _a === void 0 ? void 0 : _a.expirationDate)
                        ? dayjs(wine.generalDetails.expirationDate).format("DD/MM/YYYY")
                        : "-"}`;
                },
            },
            {
                title: i18n.t("generics.list_actions"),
                key: "actions",
                fixed: "right",
                width: 150,
                render: (item) => {
                    return (_jsx(Ant.Space, Object.assign({ size: "middle" }, { children: _jsx(Ant.Dropdown, Object.assign({ menu: { items: items(item) } }, { children: _jsxs(Ant.Button, { children: [_jsx(DownOutlined, {}, void 0), " Actions"] }, void 0) }), void 0) }), void 0));
                },
            },
        ];
        return {
            dataSource,
            columns,
        };
    }, [data, error, loading]);
    return (_jsxs(UIComponents.AdminLayout, { children: [contextHolder, _jsx(Ant.PageHeader, { title: t("management.wines.list.header"), extra: [
                    selectedWines.length > 0 ? (_jsx(Ant.Button, Object.assign({ onClick: () => {
                            setQrModal(true);
                        }, icon: _jsx(ScanOutlined, {}, void 0) }, { children: "Print QRs" }), "1")) : null,
                    features.create ? (_jsx(Ant.Button, Object.assign({ onClick: () => router.go(Routes.WINES_CREATE), icon: _jsx(PlusOutlined, {}, void 0) }, { children: t("management.wines.list.create_btn") }), "2")) : null,
                    _jsx(Ant.Button, Object.assign({ onClick: () => setFiltersOpened(!filtersOpened), icon: _jsx(FilterOutlined, {}, void 0) }, { children: t("generics.list_filters") }), "3"),
                ] }, void 0), error && _jsx(Ant.Alert, { type: "error", message: error }, void 0), _jsx(Ant.Layout.Content, { children: _jsxs("div", Object.assign({ className: "page-wines-list" }, { children: [filtersOpened && (_jsx(WinesListFilters, { onUpdate: (filters) => {
                                setFilters(filters.generalDetails);
                            } }, void 0)), _jsx(Ant.Table, Object.assign({}, tableDataSource, { className: "page-wineryMember-groups-list-table", loading: loading, scroll: { x: 1500 }, rowSelection: {
                                type: "checkbox",
                                ...rowSelection,
                            }, pagination: {
                                total: ((_a = data === null || data === void 0 ? void 0 : data.WineGetAll) === null || _a === void 0 ? void 0 : _a.limit) * ((_b = data === null || data === void 0 ? void 0 : data.WineGetAll) === null || _b === void 0 ? void 0 : _b.pageCount),
                                defaultPageSize: 10,
                                defaultCurrent: 1,
                                onChange(page) {
                                    setPage(page);
                                },
                            } }), void 0), _jsx(Ant.Modal, Object.assign({ title: "Print QR", open: qrModal, width: "60%", closable: false, onOk: () => window.print(), onCancel: () => setQrModal(false), cancelText: "Close", okText: "Print Qrs" }, { children: _jsx("div", Object.assign({ className: "page-wines-list-qr-modal" }, { children: (selectedWines !== null && selectedWines !== void 0 ? selectedWines : []).map(({ _id, qr, name }) => (_jsxs("div", Object.assign({ id: `qr-${_id}`, className: "page-wines-list-qr-modal-container" }, { children: [_jsx(QR, { value: `${process.env.QR_BASE_URL}/qr/${qr}` }, void 0), _jsx("a", Object.assign({ href: `${process.env.QR_BASE_URL}/qr/${qr}`, target: "_blank", rel: "noreferrer" }, { children: name }), void 0)] }), _id))) }), void 0) }), void 0)] }), void 0) }, void 0)] }, void 0));
}
