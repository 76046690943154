import { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Ant from "antd";
import { ADMIN_WINERY_MEMBER_GET_ALL, APP_FILES_DELETE, APP_FILE_UPLOAD, WINES_GET, } from "@bundles/UIAppBundle/queries";
import { WINE_CUSTOM_UPDATE } from "@bundles/UIAppBundle/queries/WineCustomUpdate.mutation";
import { AcceptedLanguage, UserRole, } from "@root/api.types";
import { Routes } from "@bundles/UIAppBundle";
import { useGuardian, useRouter } from "@bluelibs/x-ui";
import { formValueToData, INPUT_RULES } from "./const";
export const useWinesEdit = (props, errorMessage, successMessage) => {
    var _a, _b;
    const router = useRouter();
    const guardian = useGuardian();
    const [form] = Ant.Form.useForm();
    const [energyIndicator, setEnergyIndicator] = useState(0);
    const [energyCalculationModal, setEnergyCalculationModal] = useState(false);
    const [carbonhydrateCalculationModal, setCarbonhydrateCalculationModal] = useState(false);
    const [currentLn, setCurrentLn] = useState(AcceptedLanguage.en);
    const { data: wineFetchData, error: wineFetchError } = useQuery(WINES_GET, {
        variables: { input: { wineId: props.id } },
    });
    const [fileListCompany, setFileListCompany] = useState([]);
    const [fileListResponsibleConsumption, setFileListResponsibleConsumption] = useState([]);
    const [fileListBottle, setFileListBottle] = useState([]);
    const [sustainabilities, setSustainabilities] = useState([]);
    const [consumptions, setConsumptions] = useState([]);
    const [fetchWineryMembers, { data: fetchWineryMemberData, loading: fetchWineryMemberLoading, error: fetchWineryMemberError, },] = useLazyQuery(ADMIN_WINERY_MEMBER_GET_ALL);
    const [languageForm, setLanguageForm] = useState(Object.keys(AcceptedLanguage).map((key) => ({
        data: {
            ingredients: [],
            allergens: [],
            // varieties: [],
            // description: undefined,
        },
        lang: key,
    })));
    const [updateWine, { loading: updateWineLoading }] = useMutation(WINE_CUSTOM_UPDATE);
    const handleUpdateWine = (document) => {
        var _a, _b, _c, _d;
        const hasAtLeastOneIngredient = languageForm.some((formValue) => formValue.data.ingredients.length > 0);
        if (hasAtLeastOneIngredient) {
            const payload = {
                wineId: props.id,
                generalDetails: {
                    name: document.name,
                    brand: document.brand,
                    vintageYear: Number.parseInt(document.vintageYear),
                    country: document.country,
                    alcoholByVolumePercentage: Number.parseFloat(document.alcoholByVolumePercentage),
                    bottleSizeInMl: Number.parseInt(document.bottleSizeInMl),
                    category: document.category,
                    // ...(document?.expirationDate && {
                    //   expirationDate: document.expirationDate,
                    // }),
                    ingredients: getLanguageFieldItem("ingredients"),
                    allergens: getLanguageFieldItem("allergens"),
                    // varieties: getLanguageFieldItem("varieties"),
                    // description: [getFieldAsI18NField("description", document)],
                },
                nutritionalDetails: {
                    energyInKcal: Number.parseFloat(document.energyInKcal),
                    fatInGrams: Number.parseFloat(document.fatInGrams),
                    carbohydratesInGrams: Number.parseFloat(document.carbohydratesInGrams),
                    ofWhichSugarInGrams: Number.parseFloat(document.ofWhichSugarInGrams),
                    proteinInGrams: Number.parseFloat(document.proteinInGrams),
                    saltInMilliGrams: Number.parseFloat(document.saltInMilliGrams),
                },
                sustainabilities: sustainabilities,
                responsibleConsumptions: consumptions,
                responsibleConsumptionPhotosIds: fileListResponsibleConsumption.map((file) => file.name),
                company: {
                    name: document.companyName,
                    websiteUrl: document.companyWebsiteUrl,
                },
                lotNumber: document.lotNumber,
                ...(props.roles.includes(UserRole.ADMIN) && {
                    wineryMemberId: document.wineryMemberId,
                }),
                bottlePhotoId: (_b = (_a = fileListBottle === null || fileListBottle === void 0 ? void 0 : fileListBottle[0]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : undefined,
                companyLogoId: (_d = (_c = fileListCompany === null || fileListCompany === void 0 ? void 0 : fileListCompany[0]) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : undefined,
            };
            updateWine({ variables: { input: payload } })
                .then(() => {
                successMessage("Wine updated successfully");
                setTimeout(() => {
                    router.go(Routes.WINES_LIST);
                    location.reload();
                }, 1000);
            })
                .catch((e) => {
                errorMessage(e.message);
            });
        }
        else {
            errorMessage("Please add at least one Ingredient!");
        }
    };
    const [uploadFile, { loading: uploadFileLoading }] = useMutation(APP_FILE_UPLOAD);
    const [deleteFile, { loading: deleteFileLoading }] = useMutation(APP_FILES_DELETE);
    const handleUploadImage = (file, type) => {
        uploadFile({ variables: { upload: file } })
            .then((res) => {
            successMessage("Image uploaded successfully");
            switch (type) {
                case "company":
                    setFileListCompany(() => [
                        {
                            name: res.data.AppFileUpload._id,
                            url: res.data.AppFileUpload.downloadUrl,
                        },
                    ]);
                    break;
                case "vine":
                    setFileListBottle(() => [
                        {
                            name: res.data.AppFileUpload._id,
                            url: res.data.AppFileUpload.downloadUrl,
                        },
                    ]);
                    break;
                case "responsibleConsumption":
                    setFileListResponsibleConsumption((old) => [
                        ...old,
                        {
                            name: res.data.AppFileUpload._id,
                            url: res.data.AppFileUpload.downloadUrl,
                        },
                    ]);
                    break;
            }
        })
            .catch(() => {
            errorMessage("Error occured while uploading image");
        });
    };
    const handleDeleteImage = (_id, type) => {
        deleteFile({
            variables: {
                id: _id,
            },
        })
            .then((_) => {
            switch (type) {
                case "company":
                    setFileListCompany([]);
                    break;
                case "vine":
                    setFileListBottle([]);
                    break;
                case "responsibleConsumption":
                    setFileListResponsibleConsumption((old) => [
                        ...old.filter((file) => file.name !== _id),
                    ]);
                    break;
            }
            successMessage("Image deleted successfully");
        })
            .catch(() => {
            errorMessage("Error occured while deleting image");
        });
    };
    const handleAddLanguageFieldItem = (type, value) => {
        const items = [...languageForm];
        items.find((item) => item.lang === currentLn).data[type].push(value);
        setLanguageForm(items);
        form.resetFields([type]);
    };
    const handleRemoveLanguageFieldItem = (type, value) => {
        const items = [...languageForm];
        const filteredItems = items
            .find((item) => item.lang === currentLn)
            .data[type].filter((dataValue) => (dataValue === null || dataValue === void 0 ? void 0 : dataValue.toLowerCase()) !== (value === null || value === void 0 ? void 0 : value.toLowerCase()));
        items.find((item) => item.lang === currentLn).data[type] = filteredItems;
        setLanguageForm(items);
    };
    const getLanguageFieldItem = (type) => {
        const res = languageForm
            .map((lnForm) => {
            const items = lnForm.data[type];
            if (items.length > 0) {
                return items.map((value) => {
                    if (value) {
                        return {
                            text: "",
                            values: [
                                {
                                    text: [value],
                                    lang: lnForm.lang,
                                },
                            ],
                        };
                    }
                });
            }
        })
            .flat();
        return res.filter((values) => values);
    };
    const toggleSustainabilities = (sustainability, status) => {
        if (status) {
            setSustainabilities((old) => [...old, sustainability]);
        }
        else {
            setSustainabilities(sustainabilities.filter((s) => s !== sustainability));
        }
    };
    const toggleConsumptions = (consumption, status) => {
        if (status) {
            setConsumptions((old) => [...old, consumption]);
        }
        else {
            setConsumptions(consumptions.filter((s) => s !== consumption));
        }
    };
    const getFieldAsI18NField = (fieldName, doc) => {
        const obj = {
            values: Object.values(AcceptedLanguage).map((key) => {
                var _a, _b, _c, _d;
                const languageIndex = languageForm.findIndex((languageState) => languageState.lang === key);
                if (languageIndex !== -1) {
                    const item = key === currentLn
                        ? (doc === null || doc === void 0 ? void 0 : doc[fieldName]) && {
                            lang: currentLn,
                            text: doc[fieldName],
                        }
                        : ((_b = (_a = languageForm[languageIndex]) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[fieldName]) && {
                            lang: key,
                            text: (_d = (_c = languageForm[languageIndex]) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d[fieldName],
                        };
                    if (item)
                        return item;
                }
                else {
                    return ((doc === null || doc === void 0 ? void 0 : doc[fieldName]) && {
                        lang: currentLn,
                        text: doc[fieldName],
                    });
                }
            }),
        };
        obj.values = obj.values.filter((item) => item !== undefined);
        return obj;
    };
    const handleLanguageChange = (lang) => {
        const formValues = form.getFieldsValue();
        const findLanguageForm = languageForm.find((formData) => formData.lang === currentLn);
        const findNewLanguageForm = languageForm.find((formData) => formData.lang === lang);
        const languageStates = {
            lang: currentLn,
            data: {
                ingredients: [...findLanguageForm.data.ingredients],
                allergens: [...findLanguageForm.data.allergens],
                // varieties: [...findLanguageForm.data.varieties],
                // description: formValues.description,
            },
        };
        const langIndex = languageForm.findIndex((langState) => langState.lang === currentLn);
        const cpdLanguageForm = [...languageForm];
        cpdLanguageForm.splice(langIndex, 1);
        cpdLanguageForm.push(languageStates);
        setLanguageForm(cpdLanguageForm);
        setCurrentLn(lang);
        // form.setFieldValue("description", findNewLanguageForm.data.description);
    };
    const checkInputRule = () => {
        if (currentLn === AcceptedLanguage.en) {
            return INPUT_RULES;
        }
        return undefined;
    };
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        if (wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) {
            Object.keys(formValueToData).map((key) => {
                var _a;
                form.setFieldValue(key, formValueToData[key]
                    ? (_a = wineFetchData.WineGet[formValueToData[key]]) === null || _a === void 0 ? void 0 : _a[key]
                    : wineFetchData.WineGet[key]);
            });
            form.setFieldValue("companyName", (_b = (_a = wineFetchData.WineGet) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.name);
            form.setFieldValue("companyWebsiteUrl", (_d = (_c = wineFetchData.WineGet) === null || _c === void 0 ? void 0 : _c.company) === null || _d === void 0 ? void 0 : _d.websiteUrl);
            // if (wineFetchData.WineGet?.generalDetails?.expirationDate) {
            //   form.setFieldValue(
            //     "expirationDate",
            //     dayjs(wineFetchData.WineGet?.generalDetails?.expirationDate).format(
            //       "YYYY-MM-DD",
            //     ),
            //   );
            // }
            setSustainabilities(wineFetchData.WineGet.sustainabilities);
            setConsumptions(wineFetchData.WineGet.responsibleConsumptions);
            const lnForms = Object.keys(AcceptedLanguage).map((key) => ({
                data: {
                    ingredients: wineFetchData.WineGet.generalDetails.ingredients
                        .map((ingredient) => {
                        var _a, _b;
                        return (_b = ((_a = ingredient.values) !== null && _a !== void 0 ? _a : []).find((values) => values.lang === key)) === null || _b === void 0 ? void 0 : _b.text;
                    })
                        .flat(),
                    allergens: wineFetchData.WineGet.generalDetails.allergens
                        .map((allergen) => {
                        var _a, _b;
                        return (_b = ((_a = allergen.values) !== null && _a !== void 0 ? _a : []).find((values) => values.lang === key)) === null || _b === void 0 ? void 0 : _b.text;
                    })
                        .flat(),
                    // varieties: wineFetchData.WineGet.generalDetails.varieties
                    //   .map(
                    //     (variety) =>
                    //       (variety.values ?? []).find((values) => values.lang === key)
                    //         ?.text,
                    //   )
                    //   .flat(),
                    // description: (
                    //   wineFetchData?.WineGet?.generalDetails?.description?.[0]?.values ??
                    //   []
                    // )
                    //   .find((values) => values.lang === key)
                    //   ?.text?.join(),
                },
                lang: key,
            }));
            setLanguageForm(lnForms);
            // form.setFieldValue(
            //   "description",
            //   lnForms.find((form) => form.lang === currentLn).data.description,
            // );
            setFileListCompany(((_f = (_e = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _e === void 0 ? void 0 : _e.companyLogo) === null || _f === void 0 ? void 0 : _f.downloadUrl)
                ? [
                    {
                        uid: (_g = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _g === void 0 ? void 0 : _g.companyLogoId,
                        name: (_h = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _h === void 0 ? void 0 : _h.companyLogoId,
                        status: ((_k = (_j = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _j === void 0 ? void 0 : _j.companyLogo) === null || _k === void 0 ? void 0 : _k.downloadUrl)
                            ? "done"
                            : "error",
                        url: (_m = (_l = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _l === void 0 ? void 0 : _l.companyLogo) === null || _m === void 0 ? void 0 : _m.downloadUrl,
                    },
                ]
                : []);
            setFileListBottle(((_p = (_o = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _o === void 0 ? void 0 : _o.bottlePhoto) === null || _p === void 0 ? void 0 : _p.downloadUrl)
                ? [
                    {
                        uid: (_q = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _q === void 0 ? void 0 : _q.bottlePhotoId,
                        name: (_r = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _r === void 0 ? void 0 : _r.bottlePhotoId,
                        status: ((_t = (_s = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _s === void 0 ? void 0 : _s.bottlePhoto) === null || _t === void 0 ? void 0 : _t.downloadUrl)
                            ? "done"
                            : "error",
                        url: (_v = (_u = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _u === void 0 ? void 0 : _u.bottlePhoto) === null || _v === void 0 ? void 0 : _v.downloadUrl,
                    },
                ]
                : []);
            setFileListResponsibleConsumption((_w = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _w === void 0 ? void 0 : _w.responsibleConsumptionPhotos.map((photo, index) => {
                var _a, _b;
                return ({
                    uid: (_a = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _a === void 0 ? void 0 : _a.responsibleConsumptionPhotosIds[index],
                    name: (_b = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _b === void 0 ? void 0 : _b.responsibleConsumptionPhotosIds[index],
                    status: (photo === null || photo === void 0 ? void 0 : photo.downloadUrl) ? "done" : "error",
                    url: photo === null || photo === void 0 ? void 0 : photo.downloadUrl,
                });
            }));
            setEnergyIndicator((_z = (_y = (_x = wineFetchData === null || wineFetchData === void 0 ? void 0 : wineFetchData.WineGet) === null || _x === void 0 ? void 0 : _x.nutritionalDetails) === null || _y === void 0 ? void 0 : _y.energyInKcal) !== null && _z !== void 0 ? _z : 0);
        }
    }, [wineFetchData]);
    const handleCalculateEnergy = (payload) => {
        const { alcohol, sugar, glycerin, acid } = payload;
        const oneHundredMlCoefInLitre = 0.1;
        const alchololCoefKcPerGram = 7;
        const resSugarCoefKcPerGram = 4;
        const acidCoefKcPerGram = 3;
        const totalEnergy = Math.round(+alcohol * oneHundredMlCoefInLitre * alchololCoefKcPerGram) +
            Math.round((+sugar + +glycerin) * oneHundredMlCoefInLitre * resSugarCoefKcPerGram) +
            Math.round(+acid * oneHundredMlCoefInLitre * acidCoefKcPerGram);
        form.setFieldValue("energyInKcal", totalEnergy.toFixed(2));
        setEnergyIndicator(totalEnergy);
        setEnergyCalculationModal(false);
    };
    const handleCalculateCarbohydrate = (payload) => {
        const { carbohydrate, sugar } = payload;
        const oneHundredMlCoefInLitre = 0.1;
        const totalCarbohydrates = carbohydrate * oneHundredMlCoefInLitre + sugar * oneHundredMlCoefInLitre;
        form.setFieldValue("carbohydratesInGrams", totalCarbohydrates.toFixed(2));
        setCarbonhydrateCalculationModal(false);
    };
    useEffect(() => {
        var _a, _b;
        if ((_b = (_a = guardian === null || guardian === void 0 ? void 0 : guardian.state) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.roles.includes(UserRole.ADMIN)) {
            fetchWineryMembers();
        }
    }, [(_b = (_a = guardian === null || guardian === void 0 ? void 0 : guardian.state) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.roles]);
    return {
        form,
        languageForm,
        currentLn,
        uploadFileLoading,
        handleUpdateWine,
        checkInputRule,
        fetchWineryMemberLoading,
        fetchWineryMemberData,
        toggleSustainabilities,
        sustainabilities,
        toggleConsumptions,
        consumptions,
        fileListBottle,
        fileListResponsibleConsumption,
        fileListCompany,
        handleUploadImage,
        handleLanguageChange,
        updateWineLoading,
        wineFetchError,
        fetchWineryMemberError,
        handleAddLanguageFieldItem,
        handleRemoveLanguageFieldItem,
        handleCalculateEnergy,
        handleCalculateCarbohydrate,
        energyCalculationModal,
        setEnergyCalculationModal,
        carbonhydrateCalculationModal,
        setCarbonhydrateCalculationModal,
        energyIndicator,
        setEnergyIndicator,
        handleDeleteImage,
    };
};
