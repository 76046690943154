import { Collection, } from "@bluelibs/x-ui";
import { WineryMember } from "@root/api.types";
import { UsersCollection, WineriesCollection, } from "@bundles/UIAppBundle/collections";
export class WineryMembersCollection extends Collection {
    getName() {
        return "WineryMembers";
    }
    getInputs() {
        return {
            insert: "WineryMemberInsertInput!",
            update: "WineryMemberUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => UsersCollection,
                name: "user",
                field: "userId",
            },
            {
                collection: () => WineriesCollection,
                name: "winery",
                field: "wineryId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
