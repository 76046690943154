import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRouter, useUIComponents, useTranslate, use, I18NService, } from "@bluelibs/x-ui";
import { useState, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { features } from "../../config/features";
import { ADMIN_WINERY_MEMBER_DELETE, ADMIN_WINERY_MEMBER_GET_ALL, } from "@bundles/UIAppBundle/queries";
export function WineryMembersList() {
    const UIComponents = useUIComponents();
    const router = useRouter();
    const t = useTranslate();
    const i18n = use(I18NService);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const [messageApi, contextHolder] = Ant.message.useMessage();
    const { refetch, data, error, loading } = useQuery(ADMIN_WINERY_MEMBER_GET_ALL);
    const [deleteWineryMember] = useMutation(ADMIN_WINERY_MEMBER_DELETE);
    const handleDeleteWineryMember = (id) => {
        deleteWineryMember({ variables: { input: { wineryMemberId: id } } })
            .then(() => {
            messageApi.open({
                type: "success",
                content: "WineryMember deleted successfully",
            });
            refetch();
        })
            .catch((e) => {
            messageApi.open({
                type: "error",
                content: e.message,
            });
        });
    };
    const items = (id) => [
        {
            key: "view",
            label: "View",
            onClick: () => {
                router.go({ path: `${router.history.location.pathname}/${id}/view` });
            },
        },
        {
            key: "edit",
            label: "Edit",
            onClick: () => {
                router.go({ path: `${router.history.location.pathname}/${id}/edit` });
            },
        },
        {
            key: "delete",
            label: (_jsx(Ant.Popconfirm, Object.assign({ title: "Sure to delete?", onConfirm: () => handleDeleteWineryMember(id) }, { children: _jsx("a", { children: "Delete" }, void 0) }), void 0)),
        },
    ];
    const tableDataSource = useMemo(() => {
        var _a;
        const dataSource = (_a = data === null || data === void 0 ? void 0 : data.AdminWineryMemberGetAll) !== null && _a !== void 0 ? _a : [];
        const columns = [
            {
                title: "Username",
                dataIndex: "user",
                key: "user",
                fixed: "left",
                render: (user) => user.username,
            },
            {
                title: "Full Name",
                dataIndex: "user",
                key: "user",
                fixed: "left",
                render: (user) => `${user.firstName} ${user.lastName}`,
            },
            {
                title: "Winery",
                dataIndex: "winery",
                key: "winery",
                fixed: "left",
                render: (winery) => winery.name,
            },
            {
                title: i18n.t("generics.list_actions"),
                key: "actions",
                fixed: "right",
                width: 150,
                render: (item) => {
                    return (_jsx(Ant.Space, Object.assign({ size: "middle" }, { children: _jsx(Ant.Dropdown, Object.assign({ menu: { items: items(item.wineryMemberId) } }, { children: _jsxs(Ant.Button, { children: [_jsx(DownOutlined, {}, void 0), " Actions"] }, void 0) }), void 0) }), void 0));
                },
            },
        ];
        return {
            dataSource,
            columns,
        };
    }, [data, loading]);
    return (_jsxs(UIComponents.AdminLayout, { children: [contextHolder, error && _jsx(Ant.Alert, { type: "error", message: error }, void 0), _jsx(Ant.PageHeader, { title: t("management.winery_members.list.header"), extra: [
                    features.create ? (_jsx(Ant.Button, Object.assign({ onClick: () => router.go(Routes.WINERY_MEMBERS_CREATE), icon: _jsx(PlusOutlined, {}, void 0) }, { children: t("management.winery_members.list.create_btn") }), "1")) : null,
                    // <Ant.Button
                    //   key="2"
                    //   onClick={() => setFiltersOpened(!filtersOpened)}
                    //   icon={<FilterOutlined />}
                    // >
                    //   {t("generics.list_filters")}
                    // </Ant.Button>,
                ] }, void 0), error && _jsx(Ant.Alert, { type: "error", message: error }, void 0), _jsx(Ant.Layout.Content, { children: _jsx("div", Object.assign({ className: "page-wineryMembers-list" }, { children: _jsx(Ant.Table, Object.assign({}, tableDataSource, { pagination: false, className: "page-wineryMember-groups-list-table", loading: loading }), void 0) }), void 0) }, void 0)] }, void 0));
}
