import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useGuardian, useRouter } from "@bluelibs/x-ui";
import { useEffect } from "react";
import { notification } from "antd";
import { Routes } from "@bundles/UIAppBundle";
export function SocialAuth(props) {
    const guardian = useGuardian();
    const router = useRouter();
    useEffect(() => {
        var _a;
        const token = (_a = props.queryVariables) === null || _a === void 0 ? void 0 : _a.token;
        guardian
            .storeToken(token)
            .then(() => {
            notification.success({
                message: "Welcome!",
            });
            router.go(Routes.HOME);
        })
            .catch((err) => {
            notification.success({
                message: "Something went wrong!",
            });
            router.go(Routes.LOGIN);
        });
    }, []);
    return _jsx(_Fragment, {}, void 0);
}
