import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents, use } from "@bluelibs/x-ui";
import { useEffect, useState } from "react";
import { Card, PageHeader, Row, Col } from "antd";
import { Collections } from "@bundles/UIAppBundle";
export function Dashboard() {
    const UIComponents = useUIComponents();
    const cards = Object.values(Collections)
        .filter((v) => Boolean(v))
        .map((collectionClass, idx) => {
        if (collectionClass === null) {
            return null;
        }
        return (_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(DashboardStats, { collectionClass: collectionClass }, idx) }), idx));
    });
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(PageHeader, { title: "Dashboard" }, void 0), _jsx(Card, { children: _jsx(Row, Object.assign({ gutter: [16, 24] }, { children: cards }), void 0) }, void 0)] }, void 0));
}
export function DashboardStats(props) {
    const collection = use(props.collectionClass);
    const [count, setCount] = useState(null);
    useEffect(() => {
        collection.count({}).then((result) => {
            setCount(result);
        });
    }, []);
    return (_jsx(Card, Object.assign({ title: collection.getName() }, { children: _jsxs("h1", { children: ["Total: ", count] }, void 0) }), void 0));
}
