import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Link } from "react-router-dom";
import { useGuardian, useRouter } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import { Button, Space, Row, Col, Card, } from "antd";
export function NotFound() {
    const guardian = useGuardian();
    const router = useRouter();
    const style = { minHeight: "100vh" };
    const { isLoggedIn } = guardian.state;
    return (_jsx(Row, Object.assign({ justify: "center", align: "middle", style: style }, { children: _jsx(Col, Object.assign({ sm: 24, md: 16, lg: 8 }, { children: _jsxs(Card, Object.assign({ title: "404 Error" }, { children: [_jsx("p", { children: "The route you tried to access could not be found." }, void 0), _jsx("p", { children: _jsxs(Space, { children: [isLoggedIn && (_jsx(Link, Object.assign({ to: router.path(Routes.DASHBOARD) }, { children: _jsx(Button, { children: "Dashboard" }, void 0) }), void 0)), !isLoggedIn && (_jsx(Link, Object.assign({ to: router.path(Routes.LOGIN) }, { children: _jsx(Button, { children: "Login" }, void 0) }), void 0))] }, void 0) }, void 0)] }), void 0) }), void 0) }), void 0));
}
