import { AcceptedLanguage, WineSustainability } from "@root/api.types";
export const sustainabilitiesImages = [
    {
        name: WineSustainability.GREEN_DOT,
        image: "The_Green_Dot.png",
        style: {
            width: 80,
            height: 80,
        },
    },
    {
        name: WineSustainability.TRIMAN,
        image: "triman.png",
        style: {
            width: 80,
            height: 80,
        },
    },
    {
        name: WineSustainability.RECYCLE,
        image: "Mobius.png",
        style: {
            width: 80,
            height: 80,
        },
    },
    {
        name: WineSustainability.EURO_LEAF,
        image: "euro-leaf.png",
        style: {
            width: 80,
            height: 80,
        },
    },
    {
        name: WineSustainability.WFCP,
        image: "wfcp.png",
        style: {
            width: 80,
            height: 80,
        },
    },
    {
        name: WineSustainability.WFCP_PLUS,
        image: "wfcp-plus.png",
        style: {
            width: 80,
            height: 80,
        },
    },
    {
        name: WineSustainability.TERRA_VITIS,
        image: "terra-vitis.png",
        style: {
            width: 80,
            height: 80,
        },
    },
    {
        name: WineSustainability.EU_VEGAN,
        image: "eu-vegan.png",
        style: {
            width: 80,
            height: 80,
        },
    },
];
export const consumptionsImages = [
    {
        name: "NO_PREGNANCY",
        image: "Pregnant-woman.png",
        style: {
            width: 80,
            height: 80,
        },
    },
    {
        name: "NO_DRIVING",
        image: "responsible_drive.png",
        style: {
            width: 80,
            height: 80,
        },
    },
    {
        name: "EIGHTEEN_PLUS",
        image: "responsible_over18.png",
        style: {
            width: 80,
            height: 80,
        },
    },
    {
        name: "WINE_IN_MODERATION",
        image: "Responsible_WIM.png",
        style: {
            width: 240,
            height: 80,
        },
    },
];
export const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
export const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export const INPUT_RULES = [
    { required: true, message: "This field is required!" },
];
export const languageTabs = Object.keys(AcceptedLanguage).map((key) => ({
    key: AcceptedLanguage[key],
    label: AcceptedLanguage[key].toUpperCase(),
}));
