import "./i18n";
import { WINERIES_LIST as BASE_WINERIES_LIST, WINERIES_CREATE as BASE_WINERIES_CREATE, WINERIES_EDIT as BASE_WINERIES_EDIT, WINERIES_VIEW as BASE_WINERIES_VIEW, } from "./config/routes";
export const WINERIES_LIST = {
    ...BASE_WINERIES_LIST,
};
export const WINERIES_CREATE = {
    ...BASE_WINERIES_CREATE,
};
export const WINERIES_EDIT = {
    ...BASE_WINERIES_EDIT,
};
export const WINERIES_VIEW = {
    ...BASE_WINERIES_VIEW,
};
