import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CalculatorOutlined, LoadingOutlined, PlusOutlined, } from "@ant-design/icons";
import { useUIComponents, useTranslate, useGuardian } from "@bluelibs/x-ui";
import { WineGeneralDetailsCategory, UserRole, } from "@root/api.types";
import * as Ant from "antd";
import COUNTRIES from "@root/bundles/UIAppBundle/const/countries.json";
import { consumptionsImages, formLayout, formTailLayout, INPUT_RULES, languageTabs, sustainabilitiesImages, } from "./const";
import { useWinesCreate } from "./useWinesCreate";
import { BadgeList, CalculateCarbohydrateModal, CalculateEnergyModal, } from "@bundles/UIAppBundle/components";
export function WinesCreate() {
    var _a, _b, _c, _d;
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const [messageApi, contextHolder] = Ant.message.useMessage();
    const { state } = useGuardian();
    const { form, languageForm, currentLn, uploadFileLoading, handleCreateWine, checkInputRule, fetchWineryMemberLoading, fetchWineryMemberData, toggleSustainabilities, toggleConsumptions, fileListBottle, fileListResponsibleConsumption, fileListCompany, handleUploadImage, handleLanguageChange, createWineLoading, handleAddLanguageFieldItem, handleRemoveLanguageFieldItem, handleCalculateEnergy, handleCalculateCarbohydrate, energyCalculationModal, setEnergyCalculationModal, carbonhydrateCalculationModal, setCarbonhydrateCalculationModal, energyIndicator, setEnergyIndicator, handleDeleteImage, } = useWinesCreate({ messageApi, roles: (_a = state === null || state === void 0 ? void 0 : state.user) === null || _a === void 0 ? void 0 : _a.roles });
    const uploadButton = (_jsxs("div", { children: [uploadFileLoading ? _jsx(LoadingOutlined, {}, void 0) : _jsx(PlusOutlined, {}, void 0), _jsx("div", Object.assign({ style: { marginTop: 8 } }, { children: "Upload" }), void 0)] }, void 0));
    return (_jsxs(UIComponents.AdminLayout, { children: [contextHolder, _jsx(Ant.PageHeader, { title: t("management.wines.create.header"), onBack: () => window.history.back() }, void 0), _jsx(Ant.Card, { children: _jsxs(Ant.Form, Object.assign({}, formLayout, { form: form, onFinish: handleCreateWine }, { children: [_jsx("h1", { children: "Main Details" }, void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Name", labelAlign: "left", name: "name", rules: checkInputRule() }, { children: _jsx(Ant.Input, { placeholder: "Name" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Brand", labelAlign: "left", name: "brand" }, { children: _jsx(Ant.Input, { placeholder: "Brand" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Country", labelAlign: "left", name: "country" }, { children: _jsx(Ant.Select, Object.assign({ showSearch: true, optionFilterProp: "children", filterOption: (input, option) => {
                                    var _a;
                                    return ((_a = option === null || option === void 0 ? void 0 : option.children) !== null && _a !== void 0 ? _a : "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase());
                                }, placeholder: "Select Country", onChange: (value) => {
                                    form.setFieldValue("country", value);
                                }, allowClear: true }, { children: COUNTRIES.map((country, index) => (_jsx(Ant.Select.Option, Object.assign({ value: country.name }, { children: `${country.name}` }), index))) }), void 0) }), void 0), ((_c = (_b = state === null || state === void 0 ? void 0 : state.user) === null || _b === void 0 ? void 0 : _b.roles) !== null && _c !== void 0 ? _c : []).includes(UserRole.ADMIN) && (_jsx(Ant.Form.Item, Object.assign({ label: "Winery Member", labelAlign: "left", name: "wineryMemberId", rules: INPUT_RULES }, { children: _jsx(Ant.Select, Object.assign({ disabled: fetchWineryMemberLoading, placeholder: "Select WineryMember", onChange: (value) => {
                                    form.setFieldValue("wineryMemberId", value);
                                } }, { children: ((_d = fetchWineryMemberData === null || fetchWineryMemberData === void 0 ? void 0 : fetchWineryMemberData.AdminWineryMemberGetAll) !== null && _d !== void 0 ? _d : []).map((wineryMember) => {
                                    var _a, _b;
                                    return (_jsx(Ant.Select.Option, Object.assign({ value: wineryMember.wineryMemberId }, { children: `${(_a = wineryMember === null || wineryMember === void 0 ? void 0 : wineryMember.user) === null || _a === void 0 ? void 0 : _a.firstName} ${(_b = wineryMember === null || wineryMember === void 0 ? void 0 : wineryMember.user) === null || _b === void 0 ? void 0 : _b.lastName}` }), wineryMember.wineryMemberId));
                                }) }), void 0) }), void 0)), _jsx(Ant.Form.Item, Object.assign({ label: "LOT Number", labelAlign: "left", name: "lotNumber" }, { children: _jsx(Ant.Input, { placeholder: "LOT Number" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Vintage Year", labelAlign: "left", name: "vintageYear" }, { children: _jsx(Ant.Input, { placeholder: "Vintage Year", type: "number" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Alcohol By Volume Percentage", labelAlign: "left", name: "alcoholByVolumePercentage" }, { children: _jsx(Ant.Input, { placeholder: "Alcohol By Volume Percentage", type: "number", min: 0, max: 100, step: "0.1" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Bottle Size (ml)", labelAlign: "left", name: "bottleSizeInMl" }, { children: _jsx(Ant.Input, { placeholder: "Bottle Size (ml)", type: "number" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Category", labelAlign: "left", name: "category" }, { children: _jsx(Ant.Select, Object.assign({ placeholder: "Select Category", onChange: (value) => {
                                    form.setFieldValue("category", value);
                                } }, { children: Object.keys(WineGeneralDetailsCategory).map((key) => (_jsx(Ant.Select.Option, Object.assign({ value: key }, { children: t(`management.wines.categories.${key}`) }), key))) }), void 0) }), void 0), _jsxs("div", Object.assign({ className: "page-wines-create-select-container" }, { children: [_jsx("h1", { children: "Sustainabilities" }, void 0), _jsx("div", { children: sustainabilitiesImages.map((item, index) => (_jsx(Ant.Checkbox, Object.assign({ onChange: (e) => toggleSustainabilities(item.name, e.target.checked) }, { children: _jsx(Ant.Image, { src: `/public/${item.image}`, alt: item.name, width: item.style.width, height: item.style.height, preview: false }, void 0) }), index))) }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: "page-wines-create-select-container" }, { children: [_jsx("h1", { children: "Consumption Advice" }, void 0), _jsx("div", { children: consumptionsImages.map((item, index) => (_jsx(Ant.Checkbox, Object.assign({ onChange: (e) => toggleConsumptions(item.name, e.target.checked) }, { children: _jsx(Ant.Image, { src: `/public/${item.image}`, alt: item.name, width: item.style.width, height: item.style.height, preview: false }, void 0) }), index))) }, void 0)] }), void 0), _jsx("h1", { children: "Responsible Consumption Photos" }, void 0), _jsx(Ant.Upload, Object.assign({ listType: "picture-card", fileList: fileListResponsibleConsumption, customRequest: (o) => {
                                handleUploadImage(o.file, "responsibleConsumption");
                            }, onRemove: (file) => handleDeleteImage(file.name, "responsibleConsumption") }, { children: fileListResponsibleConsumption.length >= 8 ? null : uploadButton }), void 0), _jsx(Ant.Divider, {}, void 0), _jsx("h1", { children: "Wine Nutritional Details (per 100ml)" }, void 0), _jsxs("div", Object.assign({ className: "page-wines-create-calculate-container" }, { children: [_jsx(Ant.Form.Item, Object.assign({ className: "item", label: "Energy (kcal/kJ)", labelAlign: "left", name: "energyInKcal" }, { children: _jsx(Ant.Input, { placeholder: "Energy", type: "number", suffix: `kcal / ${Math.round((energyIndicator !== null && energyIndicator !== void 0 ? energyIndicator : 0) * 4.25).toFixed(0)} kJ`, onChange: (e) => {
                                            setEnergyIndicator(Number.parseFloat(e.target.value));
                                        } }, void 0) }), void 0), _jsxs(Ant.Button, Object.assign({ onClick: () => setEnergyCalculationModal(true) }, { children: [_jsx(CalculatorOutlined, {}, void 0), "Calculate"] }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: "page-wines-create-calculate-container" }, { children: [_jsx(Ant.Form.Item, Object.assign({ className: "item", label: "Carbohydrates (g)", labelAlign: "left", name: "carbohydratesInGrams" }, { children: _jsx(Ant.Input, { placeholder: "Carbohydrates", type: "number", suffix: "g" }, void 0) }), void 0), _jsxs(Ant.Button, Object.assign({ onClick: () => setCarbonhydrateCalculationModal(true) }, { children: [_jsx(CalculatorOutlined, {}, void 0), "Calculate"] }), void 0)] }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "of which sugar (g)", labelAlign: "left", name: "ofWhichSugarInGrams", className: "of-which-sugar" }, { children: _jsx(Ant.Input, { placeholder: "of which sugar", type: "number" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Fat (g)", labelAlign: "left", name: "fatInGrams" }, { children: _jsx(Ant.Input, { placeholder: "Fat", type: "number" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Protein (g)", labelAlign: "left", name: "proteinInGrams" }, { children: _jsx(Ant.Input, { placeholder: "Protein", type: "number" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Salt (mg)", labelAlign: "left", name: "saltInMilliGrams" }, { children: _jsx(Ant.Input, { placeholder: "Salt", type: "number" }, void 0) }), void 0), _jsx("h1", { children: "Bottle Photo" }, void 0), _jsx(Ant.Upload, Object.assign({ listType: "picture-card", fileList: fileListBottle, customRequest: (o) => {
                                handleUploadImage(o.file, "vine");
                            }, onRemove: () => handleDeleteImage(fileListBottle[0].name, "vine") }, { children: fileListBottle.length >= 8 ? null : uploadButton }), void 0), _jsx(Ant.Divider, {}, void 0), _jsx("h1", { children: "Company Photo" }, void 0), _jsx(Ant.Upload, Object.assign({ listType: "picture-card", fileList: fileListCompany, customRequest: (o) => {
                                handleUploadImage(o.file, "company");
                            }, onRemove: () => handleDeleteImage(fileListCompany[0].name, "company") }, { children: fileListCompany.length >= 8 ? null : uploadButton }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Company Name", labelAlign: "left", name: "companyName" }, { children: _jsx(Ant.Input, { placeholder: "Company Name" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Company Website", labelAlign: "left", name: "companyWebsiteUrl" }, { children: _jsx(Ant.Input, { placeholder: "Company Website" }, void 0) }), void 0), _jsx("h1", { children: "General Details" }, void 0), _jsx(Ant.Tabs, { defaultActiveKey: "en", items: languageTabs, onChange: (key) => {
                                handleLanguageChange(key);
                            } }, void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Ingredients", labelAlign: "left", name: "ingredients", required: true }, { children: _jsx(Ant.Input, { placeholder: "Ingredients", onKeyDown: (e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleAddLanguageFieldItem("ingredients", e.target.value);
                                    }
                                } }, void 0) }), void 0), _jsx(BadgeList, { values: languageForm
                                .find((formData) => formData.lang === currentLn)
                                .data.ingredients.filter((ingredient) => ingredient), onClick: (value) => {
                                handleRemoveLanguageFieldItem("ingredients", value);
                            }, className: "page-wines-create-badge-list" }, void 0), _jsx(Ant.Form.Item, Object.assign({ label: "Allergens", labelAlign: "left", name: "allergens" }, { children: _jsx(Ant.Input, { placeholder: "Allergens", onKeyDown: (e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleAddLanguageFieldItem("allergens", e.target.value);
                                    }
                                } }, void 0) }), void 0), _jsx(BadgeList, { values: languageForm
                                .find((formData) => formData.lang === currentLn)
                                .data.allergens.filter((allergen) => allergen), onClick: (value) => {
                                handleRemoveLanguageFieldItem("allergens", value);
                            }, className: "page-wines-create-badge-list" }, void 0), _jsx(Ant.Form.Item, Object.assign({}, formTailLayout, { children: _jsx(Ant.Button, Object.assign({ type: "primary", htmlType: "submit", loading: createWineLoading }, { children: t("generics.submit") }), void 0) }), void 0)] }), void 0) }, void 0), _jsx(CalculateEnergyModal, { status: energyCalculationModal, onClose: () => setEnergyCalculationModal(false), onSubmit: handleCalculateEnergy }, void 0), _jsx(CalculateCarbohydrateModal, { status: carbonhydrateCalculationModal, onClose: () => setCarbonhydrateCalculationModal(false), onSubmit: handleCalculateCarbohydrate }, void 0)] }, void 0));
}
