import { WinesList } from "../components/List/WinesList";
import { WinesCreate } from "../components/Create/WinesCreate";
import { WinesEdit } from "../components/Edit/WinesEdit";
// import { WinesView } from "../components/View/WinesView";
import { ExperimentOutlined } from "@ant-design/icons";
export const WINES_LIST = {
    path: "/wines",
    component: WinesList,
    menu: {
        key: "WINES_LIST",
        label: "management.wines.menu.title",
        icon: ExperimentOutlined,
    },
};
export const WINES_CREATE = {
    path: "/wines/create",
    component: WinesCreate,
};
export const WINES_EDIT = {
    path: "/wines/:id/edit",
    component: WinesEdit,
};
// export const WINES_VIEW: IRoute<{ id: string }> = {
//   path: "/wines/:id/view",
//   component: WinesView,
// };
