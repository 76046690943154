import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const INPUT_RULES = [{ required: true, message: "This field is required!" }];
const CalculateEnergyModal = ({ status, onSubmit, onClose, }) => {
    return (_jsx(Ant.Modal, Object.assign({ title: "Energy Calculator", open: status, onCancel: onClose, footer: null }, { children: _jsxs(Ant.Form, Object.assign({}, formLayout, { onFinish: onSubmit }, { children: [_jsx(Ant.Form.Item, Object.assign({ labelAlign: "left", label: "Alcohol (g/liter)", name: "alcohol", rules: INPUT_RULES }, { children: _jsx(Ant.Input, { type: "number", min: 0, placeholder: "Alcohol (g/liter)" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ labelAlign: "left", label: "Sugar (g/liter)", name: "sugar", rules: INPUT_RULES }, { children: _jsx(Ant.Input, { type: "number", min: 0, placeholder: "Sugar (g/liter)" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ labelAlign: "left", label: "Glycerin (g/liter)", name: "glycerin", rules: INPUT_RULES }, { children: _jsx(Ant.Input, { type: "number", min: 0, placeholder: "Glycerin (g/liter)" }, void 0) }), void 0), _jsx(Ant.Form.Item, Object.assign({ labelAlign: "left", label: "Acid (g/liter)", name: "acid", rules: INPUT_RULES }, { children: _jsx(Ant.Input, { type: "number", min: 0, placeholder: "Acid (g/liter)" }, void 0) }), void 0), _jsx(Ant.Form.Item, { children: _jsx(Ant.Button, Object.assign({ type: "primary", htmlType: "submit" }, { children: "Calculate" }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default CalculateEnergyModal;
