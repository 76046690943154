import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { useUIComponents, useRouter, useDataOne, use, useTranslate, } from "@bluelibs/x-ui";
import { ObjectId } from "@bluelibs/ejson";
import { WineryMembersCollection, } from "@bundles/UIAppBundle/collections";
import { WineryMemberViewer } from "../../config/WineryMemberViewer";
import { features } from "../../config/features";
import { useMutation } from "@apollo/client";
import { ADMIN_WINERY_MEMBER_DELETE } from "@bundles/UIAppBundle/queries";
export function WineryMembersView(props) {
    const UIComponents = useUIComponents();
    const router = useRouter();
    const t = useTranslate();
    const collection = use(WineryMembersCollection);
    // If you want to benefit of live data features use useLiveData()
    const { data: document, isLoading, error, } = useDataOne(WineryMembersCollection, new ObjectId(props.id), WineryMemberViewer.getRequestBody());
    let content;
    if (isLoading) {
        content = (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0));
    }
    else {
        if (error || document === null) {
            content = (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0));
        }
        else {
            content = _jsx(WineryMembersViewComponent, { document: document }, void 0);
        }
    }
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.winery_members.view.header"), onBack: () => window.history.back(), extra: getHeaderActions(collection, router, props.id) }, void 0), _jsx(Ant.Card, { children: content }, void 0)] }, void 0));
}
export function WineryMembersViewComponent(props) {
    const document = props.document;
    const viewer = use(WineryMemberViewer, { transient: true });
    viewer.setDocument(document);
    viewer.build();
    return (_jsx(Ant.Descriptions, { children: viewer.rest().map((item) => {
            return (_jsx(Ant.Descriptions.Item, Object.assign({ label: item.label }, { children: viewer.render(item) }), item.id));
        }) }, void 0));
}
export function getHeaderActions(collection, router, id) {
    const actions = [];
    const t = useTranslate();
    const [messageApi, contextHolder] = Ant.message.useMessage();
    const [deleteWineryMember] = useMutation(ADMIN_WINERY_MEMBER_DELETE);
    const handleDeleteWineryMember = (id) => {
        deleteWineryMember({ variables: { input: { wineryMemberId: id } } })
            .then(() => {
            messageApi.open({
                type: "success",
                content: "WineryMember deleted successfully",
            });
            router.go(Routes.WINERY_MEMBERS_LIST);
        })
            .catch((e) => {
            messageApi.open({
                type: "error",
                content: e.message,
            });
        });
    };
    if (features.edit) {
        actions.push(_jsx(Link, Object.assign({ to: router.path(Routes.WINERY_MEMBERS_EDIT, {
                params: { id },
            }) }, { children: _jsx(Ant.Button, { children: t("generics.edit") }, void 0) }), "edit"));
    }
    if (features.delete) {
        actions.push(_jsxs(Ant.Popconfirm, Object.assign({ title: "Are you sure you want to delete this WineryMember?", onConfirm: () => {
                handleDeleteWineryMember(id);
            } }, { children: [contextHolder, _jsx(Ant.Button, Object.assign({ danger: true }, { children: t("generics.delete") }), void 0)] }), "delete"));
    }
    const viewRoutePath = router.path(Routes.WINERY_MEMBERS_VIEW, {
        params: { id },
    });
    return actions;
}
