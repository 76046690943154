import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useGuardian, useRouter, useTranslate } from "@bluelibs/x-ui";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Routes } from "@bundles/UIAppBundle";
import { Form, Input, Button, Row, Col, Alert, Card, notification } from "antd";
export function Login() {
    const guardian = useGuardian();
    const tl = useTranslate("authentication.login");
    const router = useRouter();
    const [loginError, setLoginError] = useState(null);
    // const { register, handleSubmit, errors } = useForm<FormInput>();
    const onSubmit = (data) => {
        const { username, password } = data;
        guardian
            .login(username, password)
            .then(() => {
            notification.success({
                message: "Welcome!",
            });
            router.go(Routes.WINES_LIST);
        })
            .catch((err) => {
            setLoginError(err);
        });
    };
    const style = { minHeight: "100vh" };
    return (_jsx(Row, Object.assign({ justify: "center", align: "middle", style: style, className: "login-page" }, { children: _jsx(Col, Object.assign({ sm: 24, md: 12, lg: 6 }, { children: _jsx(Card, Object.assign({ title: tl("header") }, { children: _jsxs(Form, Object.assign({ className: "authentication-form", onFinish: (data) => onSubmit(data) }, { children: [_jsx(Form.Item, Object.assign({ name: "username", rules: [{ required: true }] }, { children: _jsx(Input, { prefix: _jsx(UserOutlined, { className: "site-form-item-icon" }, void 0), placeholder: tl("fields.username") }, void 0) }), void 0), _jsx(Form.Item, Object.assign({ name: "password", rules: [{ required: true }] }, { children: _jsx(Input, { prefix: _jsx(LockOutlined, { className: "site-form-item-icon" }, void 0), type: "password", placeholder: tl("fields.password") }, void 0) }), void 0), _jsx(Form.Item, { children: _jsx(Link, Object.assign({ className: "authentication-form-forgot", to: router.path(Routes.FORGOT_PASSWORD) }, { children: tl("forgotPassword_btn") }), void 0) }, void 0), _jsx(Form.Item, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", className: "authentication-form-button" }, { children: tl("login") }), void 0) }, void 0), loginError && (_jsx(Alert, { message: loginError.networkError
                                ? loginError.toString()
                                : tl("invalid_credentials"), type: "error" }, void 0))] }), void 0) }), void 0) }), void 0) }), void 0));
}
