export var AcceptedLanguage;
(function (AcceptedLanguage) {
    AcceptedLanguage["en"] = "en";
    AcceptedLanguage["at"] = "at";
    AcceptedLanguage["be"] = "be";
    AcceptedLanguage["bg"] = "bg";
    AcceptedLanguage["hr"] = "hr";
    AcceptedLanguage["cy"] = "cy";
    AcceptedLanguage["cz"] = "cz";
    AcceptedLanguage["dk"] = "dk";
    AcceptedLanguage["ee"] = "ee";
    AcceptedLanguage["fi"] = "fi";
    AcceptedLanguage["fr"] = "fr";
    AcceptedLanguage["de"] = "de";
    AcceptedLanguage["gr"] = "gr";
    AcceptedLanguage["hu"] = "hu";
    AcceptedLanguage["ie"] = "ie";
    AcceptedLanguage["it"] = "it";
    AcceptedLanguage["lv"] = "lv";
    AcceptedLanguage["lt"] = "lt";
    AcceptedLanguage["lu"] = "lu";
    AcceptedLanguage["mt"] = "mt";
    AcceptedLanguage["nl"] = "nl";
    AcceptedLanguage["pl"] = "pl";
    AcceptedLanguage["pt"] = "pt";
    AcceptedLanguage["ro"] = "ro";
    AcceptedLanguage["sk"] = "sk";
    AcceptedLanguage["si"] = "si";
    AcceptedLanguage["es"] = "es";
    AcceptedLanguage["se"] = "se";
})(AcceptedLanguage || (AcceptedLanguage = {}));
export var SubscriptionEventType;
(function (SubscriptionEventType) {
    SubscriptionEventType["added"] = "added";
    SubscriptionEventType["changed"] = "changed";
    SubscriptionEventType["removed"] = "removed";
    SubscriptionEventType["ready"] = "ready";
})(SubscriptionEventType || (SubscriptionEventType = {}));
export var UserRole;
(function (UserRole) {
    UserRole["ADMIN"] = "ADMIN";
    UserRole["WINERY_MEMBER"] = "WINERY_MEMBER";
})(UserRole || (UserRole = {}));
export var WineGeneralDetailsCategory;
(function (WineGeneralDetailsCategory) {
    WineGeneralDetailsCategory["RED_WINE"] = "RED_WINE";
    WineGeneralDetailsCategory["WHITE_WINE"] = "WHITE_WINE";
    WineGeneralDetailsCategory["ROSE_WINE"] = "ROSE_WINE";
    WineGeneralDetailsCategory["SPARKLING_WINE"] = "SPARKLING_WINE";
    WineGeneralDetailsCategory["DESSERT_WINE"] = "DESSERT_WINE";
    WineGeneralDetailsCategory["FORTIFIED_WINE"] = "FORTIFIED_WINE";
    WineGeneralDetailsCategory["OTHER"] = "OTHER";
})(WineGeneralDetailsCategory || (WineGeneralDetailsCategory = {}));
export var WineResponsibleConsumption;
(function (WineResponsibleConsumption) {
    WineResponsibleConsumption["NO_PREGNANCY"] = "NO_PREGNANCY";
    WineResponsibleConsumption["NO_DRIVING"] = "NO_DRIVING";
    WineResponsibleConsumption["EIGHTEEN_PLUS"] = "EIGHTEEN_PLUS";
    WineResponsibleConsumption["WINE_IN_MODERATION"] = "WINE_IN_MODERATION";
})(WineResponsibleConsumption || (WineResponsibleConsumption = {}));
export var WineSustainability;
(function (WineSustainability) {
    WineSustainability["GREEN_DOT"] = "GREEN_DOT";
    WineSustainability["TRIMAN"] = "TRIMAN";
    WineSustainability["RECYCLE"] = "RECYCLE";
    WineSustainability["EURO_LEAF"] = "EURO_LEAF";
    WineSustainability["WFCP"] = "WFCP";
    WineSustainability["WFCP_PLUS"] = "WFCP_PLUS";
    WineSustainability["TERRA_VITIS"] = "TERRA_VITIS";
    WineSustainability["EU_VEGAN"] = "EU_VEGAN";
})(WineSustainability || (WineSustainability = {}));
