import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import * as Ant from "antd";
import { ADMIN_WINERY_MEMBER_GET_ALL, APP_FILES_DELETE, APP_FILE_UPLOAD, WINE_CREATE, } from "@bundles/UIAppBundle/queries";
import { AcceptedLanguage, UserRole, } from "@root/api.types";
import { useAppGuardian } from "@bundles/UIAppBundle/services/AppGuardian";
import { useRouter } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import { INPUT_RULES } from "./const";
export const useWinesCreate = (props) => {
    var _a;
    const router = useRouter();
    const guardian = useAppGuardian();
    const [form] = Ant.Form.useForm();
    const [energyIndicator, setEnergyIndicator] = useState(0);
    const [energyCalculationModal, setEnergyCalculationModal] = useState(false);
    const [carbonhydrateCalculationModal, setCarbonhydrateCalculationModal] = useState(false);
    const [currentLn, setCurrentLn] = useState(AcceptedLanguage.en);
    const [fileListCompany, setFileListCompany] = useState([]);
    const [fileListResponsibleConsumption, setFileListResponsibleConsumption] = useState([]);
    const [fileListBottle, setFileListBottle] = useState([]);
    const [sustainabilities, setSustainabilities] = useState([]);
    const [consumptions, setConsumptions] = useState([]);
    const [fetchWineryMembers, { data: fetchWineryMemberData, loading: fetchWineryMemberLoading, error: fetchWineryMemberError, },] = useLazyQuery(ADMIN_WINERY_MEMBER_GET_ALL);
    const [languageForm, setLanguageForm] = useState(Object.keys(AcceptedLanguage).map((key) => ({
        data: {
            ingredients: [],
            allergens: [],
            // varieties: [],
            // description: undefined,
        },
        lang: key,
    })));
    const [createWine, { loading: createWineLoading }] = useMutation(WINE_CREATE);
    const handleCreateWine = (document) => {
        var _a, _b;
        const hasAtLeastOneIngredient = languageForm.some((formValue) => formValue.data.ingredients.length > 0);
        if (hasAtLeastOneIngredient) {
            const payload = {
                generalDetails: {
                    name: document.name,
                    brand: document.brand,
                    vintageYear: Number.parseInt(document.vintageYear),
                    country: document.country,
                    alcoholByVolumePercentage: Number.parseFloat(document.alcoholByVolumePercentage),
                    bottleSizeInMl: Number.parseInt(document.bottleSizeInMl),
                    category: document.category,
                    // ...(document?.expirationDate && {
                    //   expirationDate: document.expirationDate,
                    // }),
                    ingredients: getLanguageFieldItem("ingredients"),
                    allergens: getLanguageFieldItem("allergens"),
                    // varieties: getLanguageFieldItem("varieties"),
                    // description: [getFieldAsI18NField("description", document)],
                },
                nutritionalDetails: {
                    energyInKcal: Number.parseFloat(document.energyInKcal),
                    fatInGrams: Number.parseFloat(document.fatInGrams),
                    carbohydratesInGrams: Number.parseFloat(document.carbohydratesInGrams),
                    ofWhichSugarInGrams: Number.parseFloat(document.ofWhichSugarInGrams),
                    proteinInGrams: Number.parseFloat(document.proteinInGrams),
                    saltInMilliGrams: Number.parseFloat(document.saltInMilliGrams),
                },
                sustainabilities: sustainabilities,
                responsibleConsumptions: consumptions,
                responsibleConsumptionPhotosIds: fileListResponsibleConsumption.map((file) => file.name),
                company: {
                    name: document.companyName,
                    websiteUrl: document.companyWebsiteUrl,
                },
                lotNumber: document.lotNumber,
                ...(props.roles.includes(UserRole.ADMIN) && {
                    wineryMemberId: document.wineryMemberId,
                }),
                bottlePhotoId: (_a = fileListBottle === null || fileListBottle === void 0 ? void 0 : fileListBottle[0]) === null || _a === void 0 ? void 0 : _a.name,
                companyLogoId: (_b = fileListCompany === null || fileListCompany === void 0 ? void 0 : fileListCompany[0]) === null || _b === void 0 ? void 0 : _b.name,
            };
            createWine({ variables: { input: payload } })
                .then(() => {
                props.messageApi.open({
                    type: "success",
                    content: "Wine created successfully",
                });
                setTimeout(() => {
                    router.go(Routes.WINES_LIST);
                    location.reload();
                }, 1000);
            })
                .catch(() => {
                props.messageApi.open({
                    type: "error",
                    content: "Error occured while creating wine",
                });
            });
        }
        else {
            props.messageApi.open({
                type: "error",
                content: "Please add at least one Ingredient!",
            });
        }
    };
    const [uploadFile, { loading: uploadFileLoading }] = useMutation(APP_FILE_UPLOAD);
    const [deleteFile, { loading: deleteFileLoading }] = useMutation(APP_FILES_DELETE);
    const handleUploadImage = (file, type) => {
        uploadFile({ variables: { upload: file } })
            .then((res) => {
            props.messageApi.open({
                type: "success",
                content: "Image uploaded successfully",
            });
            switch (type) {
                case "company":
                    setFileListCompany(() => [
                        {
                            name: res.data.AppFileUpload._id,
                            url: res.data.AppFileUpload.downloadUrl,
                        },
                    ]);
                    break;
                case "vine":
                    setFileListBottle(() => [
                        {
                            name: res.data.AppFileUpload._id,
                            url: res.data.AppFileUpload.downloadUrl,
                        },
                    ]);
                    break;
                case "responsibleConsumption":
                    setFileListResponsibleConsumption((old) => [
                        ...old,
                        {
                            name: res.data.AppFileUpload._id,
                            url: res.data.AppFileUpload.downloadUrl,
                        },
                    ]);
                    break;
            }
        })
            .catch(() => {
            props.messageApi.open({
                type: "error",
                content: "Error occured while uploading image",
            });
        });
    };
    const handleDeleteImage = (_id, type) => {
        deleteFile({
            variables: {
                id: _id,
            },
        })
            .then((_) => {
            switch (type) {
                case "company":
                    setFileListCompany([]);
                    break;
                case "vine":
                    setFileListBottle([]);
                    break;
                case "responsibleConsumption":
                    setFileListResponsibleConsumption((old) => [
                        ...old.filter((file) => file.name !== _id),
                    ]);
                    break;
            }
            props.messageApi.open({
                type: "success",
                content: "Image deleted successfully",
            });
        })
            .catch(() => {
            props.messageApi.open({
                type: "error",
                content: "Error occured while deleting image",
            });
        });
    };
    const handleAddLanguageFieldItem = (type, value) => {
        const items = [...languageForm];
        items.find((item) => item.lang === currentLn).data[type].push(value);
        setLanguageForm(items);
        form.resetFields([type]);
    };
    const handleRemoveLanguageFieldItem = (type, value) => {
        const items = [...languageForm];
        const filteredItems = items
            .find((item) => item.lang === currentLn)
            .data[type].filter((dataValue) => dataValue.toLowerCase() !== value.toLowerCase());
        items.find((item) => item.lang === currentLn).data[type] = filteredItems;
        setLanguageForm(items);
    };
    const getLanguageFieldItem = (type) => {
        const res = languageForm
            .map((lnForm) => {
            const items = lnForm.data[type];
            if (items.length > 0) {
                return items.map((value) => {
                    if (value) {
                        return {
                            text: "",
                            values: [
                                {
                                    text: [value],
                                    lang: lnForm.lang,
                                },
                            ],
                        };
                    }
                });
            }
        })
            .flat();
        return res.filter((values) => values);
    };
    const toggleSustainabilities = (sustainability, status) => {
        if (status) {
            setSustainabilities((old) => [...old, sustainability]);
        }
        else {
            setSustainabilities(sustainabilities.filter((s) => s !== sustainability));
        }
    };
    const toggleConsumptions = (consumption, status) => {
        if (status) {
            setConsumptions((old) => [...old, consumption]);
        }
        else {
            setConsumptions(consumptions.filter((s) => s !== consumption));
        }
    };
    const getFieldAsI18NField = (fieldName, doc) => {
        const obj = {
            values: Object.values(AcceptedLanguage).map((key) => {
                var _a, _b, _c, _d;
                const languageIndex = languageForm.findIndex((languageState) => languageState.lang === key);
                if (languageIndex !== -1) {
                    return key === currentLn
                        ? (doc === null || doc === void 0 ? void 0 : doc[fieldName]) && {
                            lang: currentLn,
                            text: doc[fieldName],
                        }
                        : ((_b = (_a = languageForm[languageIndex]) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[fieldName]) && {
                            lang: key,
                            text: (_d = (_c = languageForm[languageIndex]) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d[fieldName],
                        };
                }
                else {
                    return ((doc === null || doc === void 0 ? void 0 : doc[fieldName]) && {
                        lang: currentLn,
                        text: doc[fieldName],
                    });
                }
            }),
        };
        obj.values = obj.values.filter((item) => item !== undefined);
        return obj;
    };
    const handleLanguageChange = (lang) => {
        const formValues = form.getFieldsValue();
        const findLanguageForm = languageForm.find((formData) => formData.lang === currentLn);
        const findNewLanguageForm = languageForm.find((formData) => formData.lang === lang);
        const languageStates = {
            lang: currentLn,
            data: {
                ingredients: [...findLanguageForm.data.ingredients],
                allergens: [...findLanguageForm.data.allergens],
                // varieties: [...findLanguageForm.data.varieties],
                // description: formValues.description,
            },
        };
        const langIndex = languageForm.findIndex((langState) => langState.lang === currentLn);
        const cpdLanguageForm = [...languageForm];
        cpdLanguageForm.splice(langIndex, 1);
        cpdLanguageForm.push(languageStates);
        setLanguageForm(cpdLanguageForm);
        setCurrentLn(lang);
        // form.setFieldValue("description", findNewLanguageForm.data.description);
    };
    const checkInputRule = () => {
        if (currentLn === AcceptedLanguage.en) {
            return INPUT_RULES;
        }
        return undefined;
    };
    if (fetchWineryMemberError) {
        props.messageApi.open({
            type: "error",
            content: fetchWineryMemberError.message,
        });
    }
    const handleCalculateEnergy = (payload) => {
        const { alcohol, sugar, glycerin, acid } = payload;
        const oneHundredMlCoefInLitre = 0.1;
        const alchololCoefKcPerGram = 7;
        const resSugarCoefKcPerGram = 4;
        const acidCoefKcPerGram = 3;
        const totalEnergy = Math.round(+alcohol * oneHundredMlCoefInLitre * alchololCoefKcPerGram) +
            Math.round((+sugar + +glycerin) * oneHundredMlCoefInLitre * resSugarCoefKcPerGram) +
            Math.round(+acid * oneHundredMlCoefInLitre * acidCoefKcPerGram);
        form.setFieldValue("energyInKcal", totalEnergy.toFixed(2));
        setEnergyIndicator(totalEnergy);
        setEnergyCalculationModal(false);
    };
    const handleCalculateCarbohydrate = (payload) => {
        const { carbohydrate, sugar } = payload;
        const oneHundredMlCoefInLitre = 0.1;
        const totalCarbohydrates = carbohydrate * oneHundredMlCoefInLitre + sugar * oneHundredMlCoefInLitre;
        form.setFieldValue("carbohydratesInGrams", totalCarbohydrates.toFixed(2));
        setCarbonhydrateCalculationModal(false);
    };
    useEffect(() => {
        var _a, _b, _c, _d, _e;
        if ((_b = (_a = guardian === null || guardian === void 0 ? void 0 : guardian.state) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.roles.includes(UserRole.ADMIN)) {
            fetchWineryMembers();
            form.setFieldValue("companyName", (_e = (_d = (_c = guardian === null || guardian === void 0 ? void 0 : guardian.state) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.winery) !== null && _e !== void 0 ? _e : "");
        }
    }, [(_a = guardian === null || guardian === void 0 ? void 0 : guardian.state) === null || _a === void 0 ? void 0 : _a.user]);
    return {
        form,
        languageForm,
        currentLn,
        uploadFileLoading,
        handleCreateWine,
        checkInputRule,
        fetchWineryMemberLoading,
        fetchWineryMemberData,
        toggleSustainabilities,
        toggleConsumptions,
        fileListBottle,
        fileListResponsibleConsumption,
        fileListCompany,
        handleUploadImage,
        handleLanguageChange,
        createWineLoading,
        handleAddLanguageFieldItem,
        handleRemoveLanguageFieldItem,
        handleCalculateEnergy,
        handleCalculateCarbohydrate,
        energyCalculationModal,
        setEnergyCalculationModal,
        carbonhydrateCalculationModal,
        setCarbonhydrateCalculationModal,
        energyIndicator,
        setEnergyIndicator,
        handleDeleteImage,
    };
};
