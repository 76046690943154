import { jsx as _jsx } from "react/jsx-runtime";
import { DownOutlined } from "@ant-design/icons";
import { useGuardian, useRouter } from "@bluelibs/x-ui";
import { Button, Dropdown, Space } from "antd";
import { Routes } from "../";
export function AdminTopHeader() {
    var _a;
    const guardian = useGuardian();
    const router = useRouter();
    const { user } = guardian.state;
    if (!guardian.state.initialised || !user) {
        return null;
    }
    const items = [
        {
            key: "1",
            label: "Change Password",
            onClick: () => router.go(Routes.CHANGE_PASSWORD),
        },
        {
            key: "2",
            label: "Logout",
            onClick: () => guardian.logout().then(() => {
                router.go(Routes.LOGIN);
            }),
        },
    ];
    return (_jsx(Space, Object.assign({ align: "end", size: 8 }, { children: _jsx(Dropdown, Object.assign({ menu: { items }, trigger: ["click"] }, { children: _jsx(Button, Object.assign({ icon: _jsx(DownOutlined, {}, void 0) }, { children: (_a = user === null || user === void 0 ? void 0 : user.profile) === null || _a === void 0 ? void 0 : _a.firstName }), void 0) }), void 0) }), void 0));
}
