import { gql } from "@apollo/client";
export const ADMIN_WINERY_MEMBER_GET_ALL = gql `
  query AdminWineryMemberGetAll {
    AdminWineryMemberGetAll {
      wineryMemberId
      user {
        username
        firstName
        lastName
      }
      winery {
        _id
        name
      }
    }
  }
`;
