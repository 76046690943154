import { UsersList } from "../components/List/UsersList";
import { UsersCreate } from "../components/Create/UsersCreate";
import { UsersEdit } from "../components/Edit/UsersEdit";
import { UsersView } from "../components/View/UsersView";
import { SettingFilled } from "@ant-design/icons";
export const USERS_LIST = {
    path: "/admin/users",
    roles: [],
    component: UsersList,
    menu: {
        key: "USERS_LIST",
        label: "management.users.menu.title",
        icon: SettingFilled,
    },
};
export const USERS_CREATE = {
    path: "/admin/users/create",
    roles: [],
    component: UsersCreate,
};
export const USERS_EDIT = {
    path: "/admin/users/:id/edit",
    roles: [],
    component: UsersEdit,
};
export const USERS_VIEW = {
    path: "/admin/users/:id/view",
    roles: [],
    component: UsersView,
};
