import { gql } from "@apollo/client";
export const WINERY_MEMBERS_FIND_ONE = gql `
  query WineryMembersFindOne($query: QueryInput) {
    WineryMembersFindOne(query: $query) {
      _id
      user {
        _id
        profile {
          firstName
          lastName
        }
        isEnabled
        email
      }
      userId
      winery {
        _id
        name
      }
      wineryId
    }
  }
`;
