import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { Link } from "react-router-dom";
import { useGuardian, useRouter } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import { Button, Space, Row, Col, Alert, Card, } from "antd";
export function NotAuthorized() {
    var _a;
    const guardian = useGuardian();
    const router = useRouter();
    const style = { minHeight: "100vh" };
    const { user, isLoggedIn } = guardian.state;
    const firstName = (_a = user === null || user === void 0 ? void 0 : user.profile) === null || _a === void 0 ? void 0 : _a.firstName;
    return (_jsx(Row, Object.assign({ justify: "center", align: "middle", style: style }, { children: _jsx(Col, Object.assign({ sm: 24, md: 16, lg: 8 }, { children: _jsxs(Card, Object.assign({ title: "Authorisation Error" }, { children: [isLoggedIn && _jsxs("p", { children: ["You are already logged in as ", firstName, "."] }, void 0), _jsx(Alert, { type: "error", message: "The page you tried to access is not authorized for you." }, void 0), _jsxs("p", { children: [_jsx("br", {}, void 0), _jsxs(Space, { children: [isLoggedIn && (_jsx(Link, Object.assign({ to: router.path(Routes.DASHBOARD) }, { children: _jsx(Button, { children: "Dashboard" }, void 0) }), void 0)), !isLoggedIn && (_jsx(Link, Object.assign({ to: router.path(Routes.LOGIN) }, { children: _jsx(Button, { children: "Login" }, void 0) }), void 0))] }, void 0)] }, void 0)] }), void 0) }), void 0) }), void 0));
}
