import { Collection, } from "@bluelibs/x-ui";
import { Wine } from "@root/api.types";
import { AppFilesCollection, } from "@bluelibs/x-ui-admin";
import { WineryMembersCollection } from "@bundles/UIAppBundle/collections";
export class WinesCollection extends Collection {
    getName() {
        return "Wines";
    }
    getInputs() {
        return {
            insert: "WineInsertInput!",
            update: "WineUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => WineryMembersCollection,
                name: "wineryMember",
                field: "wineryMemberId",
            },
            {
                collection: () => AppFilesCollection,
                name: "bottlePhoto",
                field: "bottlePhotoId",
            },
            {
                collection: () => AppFilesCollection,
                name: "companyLogo",
                field: "companyLogoId",
            },
            {
                collection: () => AppFilesCollection,
                name: "responsibleConsumptionPhotos",
                many: true,
                field: "responsibleConsumptionPhotosIds",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
