import { Collection, } from "@bluelibs/x-ui";
import { Winery } from "@root/api.types";
export class WineriesCollection extends Collection {
    getName() {
        return "Wineries";
    }
    getInputs() {
        return {
            insert: "WineryInsertInput!",
            update: "WineryUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
