import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import * as Ant from "antd";
import { ObjectId } from "@bluelibs/ejson";
import { Routes } from "@bundles/UIAppBundle";
import { useUIComponents, useRouter, use, useDataOne, useTranslate, } from "@bluelibs/x-ui";
import { WineryEditForm } from "../../config/WineryEditForm";
import { features } from "../../config/features";
import { WineriesCollection } from "@bundles/UIAppBundle/collections";
import { useMutation } from "@apollo/client";
import { ADMIN_WINERY_MEMBER_GROUP_UPDATE } from "@bundles/UIAppBundle/queries";
const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export function WineriesEdit(props) {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const form = use(WineryEditForm, { transient: true });
    const router = useRouter();
    const [messageApi, contextHolder] = Ant.message.useMessage();
    const [updateWinery] = useMutation(ADMIN_WINERY_MEMBER_GROUP_UPDATE);
    const handleFormSubmit = (doc) => {
        const { name } = doc;
        updateWinery({
            variables: {
                input: {
                    name,
                    wineryId: props.id,
                },
            },
        })
            .then(() => {
            messageApi.open({
                type: "success",
                content: "Winery updated successfully",
            });
            setTimeout(() => {
                router.go(Routes.WINERIES_LIST);
                location.reload();
            }, 1000);
        })
            .catch((e) => {
            messageApi.open({
                type: "error",
                content: e.message,
            });
        });
    };
    const { data: document, isLoading, error, } = useDataOne(WineriesCollection, new ObjectId(props.id), WineryEditForm.getRequestBody());
    form.build();
    return (_jsxs(UIComponents.AdminLayout, { children: [contextHolder, _jsx(Ant.PageHeader, { title: t("management.wineries.edit.header"), onBack: () => window.history.back(), extra: features.view
                    ? [
                        _jsx(Link, Object.assign({ to: router.path(Routes.WINERIES_VIEW, {
                                params: { id: props.id },
                            }) }, { children: _jsx(Ant.Button, { children: t("generics.view") }, void 0) }), "view"),
                    ]
                    : [] }, void 0), _jsxs(Ant.Card, { children: [isLoading && (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0)), !isLoading && (error || !document) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" }, void 0)), !isLoading && !error && (_jsxs(Ant.Form, Object.assign({}, formLayout, { initialValues: document, onFinish: handleFormSubmit }, { children: [form.render(), _jsx(Ant.Form.Item, Object.assign({}, formTailLayout, { children: _jsx(Ant.Button, Object.assign({ type: "primary", htmlType: "submit" }, { children: t("generics.submit") }), void 0) }), void 0)] }), void 0))] }, void 0)] }, void 0));
}
