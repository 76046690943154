import "./i18n";
import { WINES_LIST as BASE_WINES_LIST, WINES_CREATE as BASE_WINES_CREATE, WINES_EDIT as BASE_WINES_EDIT,
// WINES_VIEW as BASE_WINES_VIEW,
 } from "./config/routes";
export const WINES_LIST = {
    ...BASE_WINES_LIST,
};
export const WINES_CREATE = {
    ...BASE_WINES_CREATE,
};
export const WINES_EDIT = {
    ...BASE_WINES_EDIT,
};
// export const WINES_VIEW: IRoute = {
//   ...BASE_WINES_VIEW,
// };
